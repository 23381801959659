import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import theme from 'core/styles/theme';

interface FormLabelProps {
  initial?: boolean;
  bigLabel?: boolean;
}
export const FormLabel = styled.label<FormLabelProps>`
  position: absolute;
  top: 22px;
  left: 0;
  pointer-events: none;
  text-transform: capitalize;
  line-height: 24px;
  font-size: 16px;
  color: ${transparentize(0.6, theme.colors.black)};

  ${props =>
    props.initial &&
    css`
      position: static;
      text-transform: initial;
      display: block;
      margin-bottom: 10px;
    `};

  ${props =>
    props.bigLabel &&
    css`
      margin-bottom: 50px;
      font-size: 32px;
      color: ${theme.colors.black};
    `};
`;

export const SimpleFormLabel = styled.label<FormLabelProps>`
  pointer-events: none;
  font-weight: ${theme.fontWeightLight};
  line-height: 22px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  display: block;
`;

export const FieldDescription = styled.label`
  font-size: 10px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
`;
