import React, { PropsWithChildren, Suspense } from 'react';

import account from 'account/ducks/account';
import { UserRole } from 'contracts/enums';
import PageLoading from 'core/components/styled/PageLoading';

const RoleGuard: React.FC<PropsWithChildren<ComponentProps>> = ({ roles, children }) => {
  const hasRole = roles ? account.selectors.hasRole(roles) : true;
  return (hasRole ? <Suspense fallback={<PageLoading />}>{children}</Suspense> : null);
};

interface ComponentProps {
  roles?: UserRole[];
}

export default RoleGuard;
