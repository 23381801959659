import React from 'react';

import { FieldError } from 'react-hook-form';

import { FieldErrorType } from 'contracts/enums';
import translate from 'core/utils/helpers/translate';

import FormError from './styled/FormError';

const Error: React.FC<ComponentProps> = ({ error }) => {
  if (error.message) {
    return <FormError>{error.message}</FormError>;
  }
  switch (error.type) {
    case FieldErrorType.required:
      return <FormError>{translate('requiredFieldError')}</FormError>;

    case FieldErrorType.validate:
      return <FormError>{translate('invalidPromoCodeFieldError')}</FormError>;

    default:
      return <></>;
  }
};
interface ComponentProps {
  error: FieldError;
}

export default Error;
