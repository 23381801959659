import React from 'react';

import { Wrapper } from '@googlemaps/react-wrapper';
import FullStory from 'react-fullstory';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

import registerAuthInterceptor from 'account/services/registerAuthInterceptor';
import { Environments } from 'contracts/enums';
import { ModalManager } from 'core/components/Modal/ModalManager';
import NotificationList from 'core/components/NotificationList';
import GOOGLE_MAP_API_KEY from 'core/constants/GoogleMapApiKey';
import { QUERY_STALE_TIME } from 'core/constants/queryStaleTime';
import { environment } from 'core/services/environment';
import GlobalStyle from 'core/styles/base';
import theme from 'core/styles/theme';
import MainRouter from 'routing/MainRouter';

import store from './store';

registerAuthInterceptor();

declare global {
  interface Window {
    newrelic?: any;
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: QUERY_STALE_TIME,
      retry: false
    },
  },
});

// Full Story org ID
const ORG_ID = '186PDC'; 

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Wrapper apiKey={GOOGLE_MAP_API_KEY}>
          <Provider store={store}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <MainRouter />
                <ModalManager />
                <NotificationList />
              </QueryClientProvider>
            </BrowserRouter>
          </Provider>
          <Normalize />
          <GlobalStyle />
        </Wrapper>
        {environment !== Environments.local && 
          <FullStory org={ORG_ID} />
        }

      </React.Fragment>
    </ThemeProvider>
  );
};

export default App;
