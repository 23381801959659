import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { ApiUrlKey } from 'contracts/enums';
import { SalesSearchUsersFilter, SalesUserDeleteModel, SaleUserDataView, SignupSaleUserDataView } from 'contracts/types/request';
import { RoleListDataView, SalesUsersResultDataView, ServiceError } from 'contracts/types/response';
import http, { useQueryMutation, useReactQuery } from 'core/services/http';

export const useLoadUsers = (
  { page, limit, searchCriteria, sortDirection, sortOrder }: SalesSearchUsersFilter,
  customOnSuccess?: (response: SalesUsersResultDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
  UseMutationResult<SalesUsersResultDataView, AxiosError, SalesSearchUsersFilter> => 
  useQueryMutation(ApiUrlKey.loadUsers, async() => {
    const response = await http.get<SalesUsersResultDataView>(ApiUrlKey.loadUsers, {
      params: { 
        page,
        limit,
        sortOrder: sortOrder,
        sortDirection: sortDirection,
        'searchCriteria.email': searchCriteria.email,
      } });
    return response.data;
  }, customOnSuccess, customOnError);

export const useLoadRoles = (
  customOnSuccess?: (response: RoleListDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
    UseQueryResult<RoleListDataView, AxiosError> => 
  useReactQuery(ApiUrlKey.loadRoles, async() => {
    const response = await http.get<RoleListDataView>(ApiUrlKey.loadRoles);
    return response.data;
  }, customOnSuccess, customOnError);

export const useAddSalesUser = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
    UseMutationResult<Promise<never>, AxiosError<ServiceError>, SignupSaleUserDataView> =>
  useQueryMutation(
    ApiUrlKey.addSalesUser,
    (request: SignupSaleUserDataView) => http.post(ApiUrlKey.addSalesUser, request),
    customOnSuccess,
    customOnError);

export const useUpdateSalesUser = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
        UseMutationResult<Promise<never>, AxiosError<ServiceError>, SaleUserDataView> =>
  useQueryMutation(
    ApiUrlKey.addSalesUser,
    (request: SaleUserDataView) => http.put(ApiUrlKey.addSalesUser, request),
    customOnSuccess,
    customOnError);

export const useDeleteUser = (
  customOnSuccess?: () => void): 
    UseMutationResult<unknown, AxiosError<ServiceError>, SalesUserDeleteModel> => 
  useQueryMutation(
    ApiUrlKey.deleteSalesUser,
    async({ email }: SalesUserDeleteModel) => await http.delete(ApiUrlKey.deleteSalesUser, {
      data: { email }
    }),
    customOnSuccess);
