import React, { memo } from 'react';

import navigationItems from 'routing/navigationItems';
import RoleGuard from 'routing/RoleGuard';
import { SubNavigationBar, SubNavigationItem } from 'routing/styled/NavigationBar';

const NavigationSecondaryItems: React.FC<ComponentProps> = ({
  pathname,
}) => {
  const activeItem = navigationItems.find(item =>
    pathname.toLowerCase().startsWith(`/${item.path.split('/')[1]}`),
  );
  
  if (!activeItem) {
    return null;
  }

  const hiddenSubmenuItems = [] as string[];

  let subitems = activeItem.subItems ? Object.values(activeItem.subItems) : [];
  subitems = subitems.filter(
    entry => !hiddenSubmenuItems.includes(entry.label));

  return (
    subitems.length > 0 ? 
      <SubNavigationBar>
        {subitems
          .filter(
            entry =>
              entry.path &&
            entry.label
          )
          .map(item => (
            <RoleGuard key={item.path} roles={item.authorizedUserRoles}>
              <SubNavigationItem
                to={item.path}
              >
                {item.label}
              </SubNavigationItem>
            </RoleGuard>

          ))}
      </SubNavigationBar>
      : null
  );
};

interface ComponentProps {
  pathname: string;
}

export default memo(NavigationSecondaryItems);
