import React from 'react';

import filter from 'lodash-es/filter';
import isString from 'lodash-es/isString';
import { useDispatch } from 'react-redux';

import { queryClient } from 'App';
import { ApiUrlKey } from 'contracts/enums';
import { SaleUserFormData } from 'contracts/types/form';
import { SignupSaleUserDataView } from 'contracts/types/request';
import { SalesUserDataView } from 'contracts/types/response';
import { Modal, ModalDialog, ModalClose, ModalTitle } from 'core/components/styled/Modal';
import { createSuccessNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/utils/helpers/translate';
import { useAddSalesUser, useLoadRoles, useUpdateSalesUser } from 'users/services/users';

import UserEditorForm from '../forms/UserEditorForm';

const UserEditorModal: React.FC<OwnProps> = ({ user, userIndex, closeModal }) => {

  const dispatch = useDispatch();

  const { data: roles, isLoading: isLoadingRoles } = useLoadRoles();

  const { isLoading: isCreatingUser, mutateAsync: doCreateUser } = 
  useAddSalesUser(() => {
    dispatch(createSuccessNotificationMessage(translate('users.userSavedSuccesfully')));
    queryClient.invalidateQueries(ApiUrlKey.getPromoCodes);
  });
  const { isLoading: isUpdatingUser, mutateAsync: doUpdateUser } = 
  useUpdateSalesUser(() => {
    dispatch(createSuccessNotificationMessage(translate('users.userSavedSuccesfully')));
    queryClient.invalidateQueries(ApiUrlKey.getPromoCodes);
  });

  const saveUser = async({ email, salesUserGroupId, roles }: SaleUserFormData): Promise<void> => {
    const filterRoles = filter(roles, role => isString(role) );
    if (userIndex >= 0) {
      const request: SaleUserFormData = {
        email: email,
        salesUserGroupId: salesUserGroupId,
        roles: filterRoles
      };
      await doUpdateUser(request);
    } else {
      const request: SignupSaleUserDataView = {
        callBackURL: ApiUrlKey.userCallbackURL,
        email: email,
        salesUserGroupId: salesUserGroupId,
        roles: filterRoles
      };

      await doCreateUser(request);
    }
    closeModal(true);
  };

  return (
    <Modal size="small" isLoading={isCreatingUser || isUpdatingUser || isLoadingRoles}>
      <ModalDialog>
        <ModalClose onClick={closeModal as () => void} />
        <ModalTitle center>{user ? translate('users.editUser') : translate('users.createUser')}</ModalTitle>
        <UserEditorForm onSubmit={saveUser} user={user} userIndex={userIndex} roles={roles?.roles}/>
      </ModalDialog>
    </Modal>
  );
};

interface OwnProps {
  user: SalesUserDataView,
  userIndex: any,
  closeModal: (arg?: boolean) => void;
}

export default UserEditorModal;
