import React, { useState } from 'react';

import { FieldError, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { queryClient } from 'App';
import { ApiUrlKey } from 'contracts/enums';
import { UploadFileModalFormData } from 'contracts/types/form';
import { ModalComponentProps } from 'contracts/types/modal';
import FileSelect from 'core/components/HookForm/FileSelect';
import Input from 'core/components/HookForm/Input';
import { Button } from 'core/components/styled/Button';
import { ButtonSet } from 'core/components/styled/ButtonSet';
import { Modal, ModalClose, ModalDialog, ModalTitle } from 'core/components/styled/Modal';
import { createErrorNotificationMessage, createSuccessNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/utils/helpers/translate';
import { useUploadFile } from 'quote/services/quotedFiles';

const UploadFileModal: React.FC<UploadFileModalProps> = ({ displayCustomerName, closeModal }) => {
  const dispatch = useDispatch();

  const { setValue, handleSubmit } = useForm<UploadFileModalFormData>({
    mode: 'onBlur',
  });
  const [file, setFile] = useState<File | undefined>();
  const [custName, setCustName] = useState<string | undefined>();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const { isLoading: isUploadingFile, mutate: doUploadFile } = useUploadFile(() => {
    dispatch(createSuccessNotificationMessage(translate('quote.fileWasUploadedSuccessfully')));
    queryClient.invalidateQueries(ApiUrlKey.getQuotes);
    closeModal();
  }, error => {
    if (error.response?.status === 400) {
      dispatch(createErrorNotificationMessage(translate('quote.invalidFile')));
    } else
      dispatch(createErrorNotificationMessage(translate('quote.thereWasAProblemWithTheUploadedFile')));
  });
  const onFileUploaded = (file: File): void => {
    setValue('file', file);
    setFile(file);
  };

  const onCustNameChange = (customerName: string): void => {
    setValue('customerName', customerName);
    setCustName(customerName);
  };
  const onSubmit = ({ customerName, file }: UploadFileModalFormData): void => {
    setIsSubmited(true);
    if (customerName && file) {
      const request = new FormData();
      request.append('CustomerName', customerName);
      request.append('File', file);
      doUploadFile(request);
    }
  };

  return (<Modal isLoading={isUploadingFile}>
    <ModalDialog>
      <ModalClose onClick={() => closeModal()} />
      <ModalTitle>{translate('quote.uploadFile')}</ModalTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {displayCustomerName ?
          <Input
            label={translate('quote.customerName')}
            type='text'
            onChange={e => onCustNameChange(e.target.value)}
            errors={!custName && isSubmited ? ({ message: translate('validator.requiredField') } as FieldError) : undefined}
          /> : null}
        <FileSelect
          onFileUploaded={onFileUploaded}
          errors={!file && isSubmited ? ({ message: translate('validator.requiredField') } as FieldError) : undefined}
        />
        <ButtonSet>
          <Button >{translate('quote.upload')}</Button>
        </ButtonSet>
      </form>
    </ModalDialog>
  </Modal>
  );
};

export interface UploadFileModalProps extends ModalComponentProps {
  displayCustomerName?: boolean;
}

export default UploadFileModal;
