import { get, set, remove } from 'local-storage';

import { SuccessLoginModel } from 'contracts/types/response';

enum LocalStorageKey {
  user = 'rubiconSalesUser',
  authToken = 'rubiconSalesAuthToken',
  refreshToken = 'rubiconSalesRefreshToken',
}

const setSessionItem = <T>(key: LocalStorageKey, value: T): boolean => set<T>(key, value);

const getSessionItem = <T>(key: LocalStorageKey): T => get<T>(key);

export const createSession = (user: SuccessLoginModel, authToken: string, refreshToken?: string): void => {
  if (user) {
    setSessionItem(LocalStorageKey.user, user);
  }
  if (authToken) {
    setSessionItem(LocalStorageKey.authToken, authToken);
  }
  if (refreshToken) {
    setSessionItem(LocalStorageKey.refreshToken, refreshToken);
  }
};

export const getSessionUser = (): SuccessLoginModel => getSessionItem<SuccessLoginModel>(LocalStorageKey.user);

export const getSessionAuthToken = (): string => getSessionItem(LocalStorageKey.authToken);

export const getSessionRefreshToken = (): string => getSessionItem(LocalStorageKey.refreshToken);

export const destroySession = (): void => {
  remove(LocalStorageKey.user);
  remove(LocalStorageKey.authToken);
  remove(LocalStorageKey.refreshToken);
};
