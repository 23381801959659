import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { ApiUrlKey } from 'contracts/enums';
import { ExceptionQuoteUnavailableDataView, ProvidedQuotePriceDataView, SalesExceptionQuoteFilter } from 'contracts/types/request';
import { CustomQuoteVendorsDataView, ExceptionQuoteDataView, ExceptionQuotePagedListDataView, ServiceError } from 'contracts/types/response';
import http, { useQueryMutation, useReactQuery } from 'core/services/http';

export const useGetCustomQuotes = (
  requestType: string,
  pageNr: number,
  request: SalesExceptionQuoteFilter,
  customOnSuccess?: (response: ExceptionQuotePagedListDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
  UseQueryResult<ExceptionQuotePagedListDataView, AxiosError> => 
  useReactQuery([ApiUrlKey.getCustomQuotes, requestType, pageNr], async() => {
    const response = await http.get<ExceptionQuotePagedListDataView>(ApiUrlKey.getCustomQuotes, {
      params: request });
    return response.data;
  }, customOnSuccess, customOnError);

export const useGetCustomQuotesMut = (
  requestType: string,
  pageNr: number,
  customOnSuccess?: (response: ExceptionQuotePagedListDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
      UseMutationResult<ExceptionQuotePagedListDataView, AxiosError<ServiceError>, SalesExceptionQuoteFilter> => 
  useQueryMutation(
    [ApiUrlKey.getCustomQuotes, requestType, pageNr],
    async(request: SalesExceptionQuoteFilter) => {
      const response = await http.get<ExceptionQuotePagedListDataView>(ApiUrlKey.getCustomQuotes, {
        params: {
          status: request.status,
          page: request.page,
          limit: request.limit,
          sortOrder: request.sortOrder,
          sortDirection: request.sortDirection,
          'SearchCriteria.QuoteId': request.searchCriteria ? request.searchCriteria.quoteId : undefined,
          'SearchCriteria.FormatedAddress': request.searchCriteria ? request.searchCriteria.formatedAddress : undefined,
        } });
      return response.data;
    }, customOnSuccess, customOnError); 

export const useGetCustomQuote = (
  customOnSuccess?: (response: ExceptionQuoteDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
  UseMutationResult<ExceptionQuoteDataView, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.customQuote,
    async(quoteId: number) => {
      const response = await http.get<ExceptionQuoteDataView>(ApiUrlKey.customQuote + `/${quoteId}`);
      return response.data;
    }, customOnSuccess, customOnError);

export const useRequestCustomQuote = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
      UseMutationResult<unknown, AxiosError<ServiceError>, ExceptionQuoteDataView> => 
  useQueryMutation(
    ApiUrlKey.customQuote,
    async(request: ExceptionQuoteDataView) => {
      const response = await http.post(ApiUrlKey.customQuote, request);
      return response.data;
    }, customOnSuccess, customOnError, false);

export const useServiceUnavailable = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
      UseMutationResult<ExceptionQuoteDataView, AxiosError<ServiceError>, ExceptionQuoteUnavailableDataView> => 
  useQueryMutation(
    ApiUrlKey.serviceUnavailable,
    async(request: ExceptionQuoteUnavailableDataView) => {
      const response = await http.post<ExceptionQuoteDataView>(ApiUrlKey.serviceUnavailable, request);
      return response.data;
    }, customOnSuccess, customOnError);

export const searchVendorDetails = async(searchTerm: string): Promise<CustomQuoteVendorsDataView> => {
  const response = await http.get<CustomQuoteVendorsDataView>(`${ApiUrlKey.searchVendorDetails}?keyword=${encodeURIComponent(searchTerm)}`);
  return response.data;
};

export const useProvideQuotePrice = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
          UseMutationResult<ExceptionQuoteDataView, AxiosError<ServiceError>, ProvidedQuotePriceDataView> => 
  useQueryMutation(
    ApiUrlKey.provideQuotePrice,
    async(request: ProvidedQuotePriceDataView) => {
      const response = await http.post(ApiUrlKey.provideQuotePrice, request);
      return response.data;
    }, customOnSuccess, customOnError, false);
