import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { reducer as accountReducer } from 'account/ducks/account';
import { ApplicationState } from 'contracts/types/state';
import coreReducer from 'core/ducks/reducer';

const rootReducer = combineReducers<ApplicationState>({
  core: coreReducer,
  account: accountReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;