import React, { useEffect } from 'react';

import { queryClient } from 'App';
import { ApiUrlKey, ShoppingCartStatus } from 'contracts/enums';
import { ModalComponentProps } from 'contracts/types/modal';
import CartSummary from 'core/components/CartSummary';
import { Button, ButtonIcon, ButtonLink } from 'core/components/styled/Button';
import { ButtonSet, ButtonSetExplain, ButtonSetRow, ButtonSetSection } from 'core/components/styled/ButtonSet';
import { Modal, ModalClose, ModalDialog, ModalTitle } from 'core/components/styled/Modal';
import modalManager from 'core/ducks/modalManager';
import { createErrorNotificationMessage, createSuccessNotificationMessage } from 'core/ducks/notifier';
import { useDetermineAllRules } from 'core/services/smbPresale';
import Colors from 'core/styles/colors';
import translate from 'core/utils/helpers/translate';
import { useCreateProposalCart, useDeleteProposalCart, useDeleteShoppingCart, useDownloadProposalCart, useGetShoppingCartDocumentsById, useLoadProposalCart, useLoadShoppingCart, useOpenForSaleShoppingCartAsSalesPerson, useResendShoppingCart } from 'dashboard/services/shoppingCart';
import { useDispatch } from 'react-redux';

import ShoppingCartContactList from '../dashboardPageSections/ShoppingCartContactList';

const ShoppingCartDetailsModal: React.FC<ShoppingCartDetailsModalProps> = ({ status, id, closeModal }) => {
  const dispatch = useDispatch();

  const { isLoading: isLoadingAllRules, data: allRulesData } = useDetermineAllRules();

  const { data: proposalCart, isLoading: isLoadingProposalCart, mutate: doLoadProposalCart } = useLoadProposalCart(
    () => {

    }, () => {
      dispatch(createErrorNotificationMessage([
        translate('core.systemCannotRegenerateTheCart'),
        translate('core.serviceAvailabilityAndPricing'),
        translate('core.salesForceOpportunityIsComplete'),
      ]));
      closeModal();
    });

  const { data: shoppingCart, isLoading: isLoadingShoppingCart, mutate: doLoadShoppingCart } = useLoadShoppingCart(
    () => {
      if (status !== ShoppingCartStatus.sold) {
        doLoadShoppingCartUrl(id);
      } else {
        doGetShoppingCartDocuments(id);
      }
    }, () => {
      dispatch(createErrorNotificationMessage([
        translate('core.systemCannotRegenerateTheCart'),
        translate('core.serviceAvailabilityAndPricing'),
        translate('core.salesForceOpportunityIsComplete'),
      ]));
      closeModal();
    });

  const { data: signWithCustomerUrl, isLoading: isLoadingShoppingCartUrl, mutate: doLoadShoppingCartUrl } =
    useOpenForSaleShoppingCartAsSalesPerson(() => {
      dispatch(createErrorNotificationMessage([
        translate('core.systemCannotRegenerateTheCart'),
        translate('core.serviceAvailabilityAndPricing'),
        translate('core.salesForceOpportunityIsComplete'),
      ]));
      closeModal();
    });

  const { data: electronicDocuments, isLoading: isGetingShoppingCartDocuments, mutate: doGetShoppingCartDocuments } =
    useGetShoppingCartDocumentsById();
  const { isLoading: isDownloadingProposalCart, mutate: doDownloadProposalCart } =
    useDownloadProposalCart();

  const { isLoading: isCreatingProposalCart, mutate: doCreateProposalCart } = useCreateProposalCart(() => {
    dispatch(createSuccessNotificationMessage(translate('dashboard.theShoppingCartWasCreatedSuccessfully')));
    queryClient.invalidateQueries(ApiUrlKey.getShoppingCarts);
    closeModal();
  });

  const { isLoading: isDeletingProposalCart, mutate: doDeleteProposalCart } = useDeleteProposalCart(() => {
    dispatch(createSuccessNotificationMessage(translate('dashboard.theShoppingCartWasDeletedSuccessfully')));
    queryClient.invalidateQueries(ApiUrlKey.getShoppingCarts);
    closeModal();
  });

  const { isLoading: isDeletingShoppingCart, mutate: doDeleteShoppingCart } = useDeleteShoppingCart(() => {
    dispatch(createSuccessNotificationMessage(translate('dashboard.theShoppingCartWasDeletedSuccessfully')));
    queryClient.invalidateQueries(ApiUrlKey.getShoppingCarts);
    closeModal();
  });

  const { isLoading: isResendingShoppingCart, mutate: doResendShoppingCart } = useResendShoppingCart(() => {
    dispatch(createSuccessNotificationMessage(translate('dashboard.theEmailWasSentSuccessfully')));
    queryClient.invalidateQueries(ApiUrlKey.getShoppingCarts);
    closeModal();
  });

  useEffect(() => {
    if (status === ShoppingCartStatus.proposed) {
      doLoadProposalCart(id);
    } else {
      doLoadShoppingCart(id);
    }
    return (() => {

    });
  }, [id, doLoadProposalCart, doLoadShoppingCart, status]);
  const isLoading = isLoadingProposalCart ||
    isLoadingShoppingCart ||
    isLoadingAllRules;

  const isLoadingAction = isDownloadingProposalCart ||
    isCreatingProposalCart ||
    isDeletingProposalCart ||
    isDeletingShoppingCart ||
    isResendingShoppingCart;

  const downloadProposalCart = (): void => {
    doDownloadProposalCart({ proposalId: id, fileName: `Proposal-${id}.pdf` });
  };

  const createProposalCart = (): void => {
    dispatch(modalManager.openConfirmationModal(translate('dashboard.areYouSureYouWantToCreateThisShoppingCart'), '', value => {
      if (value) {
        doCreateProposalCart(id);
      }
    }));
  };

  const resendShoppingCart = (): void => {
    doResendShoppingCart(id);
  };

  const deleteShoppingCart = (): void => {
    dispatch(modalManager.openConfirmationModal(translate('dashboard.areYouSureYouWantToDeleteThisShoppingCart'), '', value => {
      if (value) {
        if (status === ShoppingCartStatus.proposed) {
          doDeleteProposalCart(id);
        } else {
          doDeleteShoppingCart(id);
        }
      }
    }));
  };

  const renderProposalCart = (): JSX.Element | null => {
    if (!proposalCart) return null;
    return (
      <>
        <ModalTitle bold center margin="no no small">{translate('dashboard.proposal')}</ModalTitle>

        <CartSummary
          isLoading={isLoadingProposalCart || isLoading}
          locations={proposalCart.locations}
          allRules={allRulesData}
        />
        <ButtonSet isLoading={isDownloadingProposalCart} margin="large no no">
          {!isDownloadingProposalCart ?
            <>
              <ButtonSetRow>
                <Button
                  noBackground
                  fluid
                  size="small"
                  margin="no small no"
                  disabled={isLoadingAction}
                  onClick={downloadProposalCart}
                >
                  <ButtonIcon icon="download" /> {isDownloadingProposalCart ? translate('downloading') : translate('dashboard.openProposal')}
                </Button>
                <Button fluid size="small" disabled={isLoadingAction} onClick={createProposalCart}>
                  {translate('dashboard.createCart')}
                </Button>
              </ButtonSetRow>
              <ButtonSetRow>
                <Button text color={Colors.alert} size="small" disabled={isLoadingAction} onClick={deleteShoppingCart}>
                  <ButtonIcon icon="delete" /> {translate('dashboard.deleteFromDashboard')}
                </Button>
              </ButtonSetRow>
            </> : null}
        </ButtonSet>
      </>);
  };

  const renderShoppingCart = (): JSX.Element | null => {
    if (!shoppingCart) return null;
    return (<>
      <ModalTitle margin="no no small">{translate('dashboard.contacts')}</ModalTitle>
      <ShoppingCartContactList margin="no no medium" contacts={shoppingCart.contacts} />

      <ModalTitle margin="no no small">{translate('dashboard.services')}</ModalTitle>
      <CartSummary
        isLoading={isLoading || isLoadingShoppingCart}
        locations={shoppingCart.locations}
        allRules={allRulesData}
      />

      {status !== ShoppingCartStatus.sold ?
        <ButtonSet isLoading={isLoadingShoppingCartUrl} margin="large no no">
          {signWithCustomerUrl && !isResendingShoppingCart ? <>
            <ButtonSetRow>
              <Button fluid size="small" margin="no small no" disabled={isLoadingAction} onClick={resendShoppingCart}>
                <ButtonIcon icon="send" />
                {translate('dashboard.resendLink')}
              </Button>
              <ButtonLink fluid size="small" target="_blank" href={signWithCustomerUrl.url}>
                <ButtonIcon icon="open" />
                {translate('dashboard.openLink')}
              </ButtonLink>
            </ButtonSetRow>
            <ButtonSetRow>
              <Button text color={Colors.alert} size="small" disabled={isLoadingAction} onClick={deleteShoppingCart}>
                <ButtonIcon icon="delete" /> {translate('dashboard.deleteFromDashboard')}
              </Button>
            </ButtonSetRow></> : null}
        </ButtonSet>
        :
        <ButtonSet isLoading={isGetingShoppingCartDocuments} margin="large no no">
          {electronicDocuments
            ? <>
              {electronicDocuments.serviceAgreement &&
                electronicDocuments.serviceAgreement.downloadUrl &&
                <ButtonSetSection>
                  <ButtonSetExplain>{translate('dashboard.serviceAgreement')}</ButtonSetExplain>
                  <ButtonLink target="_blank" href={electronicDocuments.serviceAgreement.downloadUrl}>
                    <ButtonIcon icon="download" />
                    {translate('download')}
                  </ButtonLink>
                </ButtonSetSection>}
              {electronicDocuments.letterOfAuthorization &&
                electronicDocuments.letterOfAuthorization.downloadUrl &&
                <ButtonSetSection>
                  <ButtonSetExplain>{translate('dashboard.letterOfAuthorization')}</ButtonSetExplain>
                  <ButtonLink target="_blank" href={electronicDocuments.letterOfAuthorization.downloadUrl}>
                    <ButtonIcon icon="download" />
                    {translate('download')}
                  </ButtonLink>
                </ButtonSetSection>}
            </>
            : null}
        </ButtonSet>
      }
    </>);
  };

  return (<Modal isLoading={isLoadingShoppingCart || isLoadingProposalCart}>
    <ModalDialog>
      <ModalClose onClick={() => closeModal()} />
      {status === ShoppingCartStatus.proposed ? renderProposalCart() : renderShoppingCart()}
    </ModalDialog>
  </Modal>
  );
};

export interface ShoppingCartDetailsModalProps extends ModalComponentProps {
  status: ShoppingCartStatus;
  id: number;
}

export default ShoppingCartDetailsModal;
