import React, { useState } from 'react';

import map from 'lodash-es/map';
import { FieldError } from 'react-hook-form';

import Select from 'core/components/HookForm/Select';
import { GROUP_TYPES } from 'users/constants/groupTypes';

const UserGroupTypeSelect = React.forwardRef<HTMLInputElement, OwnProps>(
  ({ salesUserGroupId, placeHolder, onGroupSelect, errors, ...props }, ref) => {
    
    const [value, setValue] = useState(salesUserGroupId ? salesUserGroupId : undefined);
    const groupTypeOptions = map(GROUP_TYPES, ({ name, value }) => ({
      label: name,
      code: value,
    }));

    const onGroupChange = (group: string): void => {
      const request = parseInt(group);
      onGroupSelect(request);
      setValue(request);
    };

    return (
      <Select
        label={placeHolder}
        placeholder={placeHolder}
        defaultValue={value}
        options={groupTypeOptions}
        {...props}
        onChange={e => onGroupChange(e.target.value)}
        errors={errors}
        value={value || ''}
      >
      </Select>
    );
  });

  interface OwnProps {
    salesUserGroupId?: number | null;
    placeHolder?: string;
    onGroupSelect: (groupId: number) => void;
    errors?: FieldError;
  }

export default UserGroupTypeSelect;
