import { transparentize } from 'polished';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

export const InputStyle = (props: ThemedStyledProps<InputProps, DefaultTheme>): string => `
  width: 100%;
  padding: 2px 2px 8px 0;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid ${transparentize(0.9, '#000')};
  border-radius: 0;
  line-height: 24px;
  font-size: 16px;
  color: ${transparentize(0.1, '#000')};

  &:focus {
    border-bottom-color: ${props.theme.colors.brandPrimary};
  }

  &:disabled {
    background-color: ${transparentize(0.98, '#000')};
  }`;

interface InputProps {
  iconPadding?: boolean;
}
export const Input = styled.input<InputProps>`
  ${InputStyle};

  ${props =>
    props.iconPadding &&
    css`
      padding-left: 50px;
    `};
`;
