import styled from 'styled-components';

import Icon from 'core/components/Icon';

export const PageBackButtonIcon = styled(Icon).attrs({ icon: 'back' })`
  width: 21px;
  height: 21px;
  margin-right: 8px;
  cursor: pointer;
`;

export const PageBackButton = styled.div`
  height: 40px;
  width: 20px;
  color: ${props => props.theme.colors.brandPrimary};
  display:inline-block;
  margin-right: 15px;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
