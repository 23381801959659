import React, { PropsWithChildren, Suspense } from 'react';

import { Navigate, Path, useLocation } from 'react-router';

import account from 'account/ducks/account';
import PageLoading from 'core/components/styled/PageLoading';

import { AccountRoutes } from './Routes';

const GuestRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const isLoggedIn = account.selectors.isLoggedIn();
  const { state } = useLocation();
  const redirectPath = state && (state as Path).pathname ? (state as Path).pathname : '/';
  const redirectTo = redirectPath !== AccountRoutes.root + AccountRoutes.logout ? redirectPath : '/';
  return isLoggedIn ? <Navigate to={redirectTo} /> : <Suspense fallback={<PageLoading />}>{children}</Suspense>;
};

export default GuestRoute;
