enum ModalType {
  confirmation = 'confirmation',
  uploadFile = 'uploadFile',
  shoppingCartDetails = 'shoppingCartDetails',
  uploadPromoCode = 'uploadPromoCode',
  uploadBulkFile = 'uploadBulkFile',
  contactForm = 'contactForm',
  usersForm = 'usersForm',
  serviceUnavailableReason = 'serviceUnavailableReason'
}

export default ModalType;
