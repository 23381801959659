export enum AccountRoutes {
  root = '/account',
  login = '/login',
  logout = '/logout',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  passwordResetEmailSent = '/password-reset-email-sent',
  setPassword = '/set-password',
  expiredPassword = '/expired-password-reset'
}

export enum QuoteRoutes {
  root = '/quote',
  singleService = '/service',
  singleServiceWithId = '/service/:id', // ^[0-9A-Za-z]{18,32}$
  bulkFile = '/bulk',
  customQuote = '/custom',
  viewCustomQuote = '/custom/:customQuoteId', // ^[0-9]{1,100}$
  createCustomQuote = '/custom/create',
  promoCode = '/promo-code'
}

export enum DashboardRoutes {
  root = '/dashboard',
}

export enum ServiceRoutes {
  sale = '/sale/service/:id', // ^[0-9a-z]{18,32}$
  proposal = '/proposal/service/:id', // ^[0-9a-z]{18,32}$
  proposalEdit = '/proposal/service/:id/edit', // ^[0-9a-z]{18,32}$
}

export enum VendorRoutes {
  root = '/vendor-cost',
  serviceRates = '/service-rates',
  customRates = '/custom-service-rates',
  customRate = '/custom-service-rates/:customQuoteId', // ^[0-9]{1,100}$
  authorize = '/custom-service-rates/authorize'
}

export enum MarketRoutes {
  root = '/market',
  marketAvailability = '/market-availability',
  marketConfiguration = '/market-configuration',
  marginMinimum = '/market-margin-minimum',
  bulkRates = '/upload-rates'
}

export enum UsersRoutes {
  root = '/users',
}
