enum UserRole {
  admin = 'RubiconSalesAdmin',
  configurationUser = 'RubiconSalesConfigurationUser',
  manager = 'RubiconSalesManager',
  salesPerson = 'RubiconSalesPerson',
  salesIHR = 'RubiconSalesIHR',
  bulkPricing = 'RubiconBulkPricing',
  bulkPricingDetailedView = 'RubiconBulkPricingDetailedView',
  rateUploadUser = 'RateUploadUser',
}

export default UserRole;
