import { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { Location, useLocation } from 'react-router';

import modalManager from 'core/ducks/modalManager';
import { ModalActionProps, ModalComponentProps } from 'contracts/types/modal';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useOnLocationChange = (callback: (location?: Location) => void): void => {
  const location = useLocation();
  
  useEffect(() => {
    callback(location);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export const useModalManager = <T extends ModalComponentProps = ModalComponentProps>(): {
  openModal: (props: ModalActionProps<T>) => void;
  openConfirmation: (title: string,
    message: string,
    closeModal: (value?: boolean) => void) => void;
  closeModal: (id: string) => void;
} => {
  const dispatch = useDispatch();
  
  const openModal = (props: ModalActionProps<T>): void => {
    dispatch(modalManager.openModal(props));
  };

  const openConfirmation = (title: string,
    message: string,
    closeModal: (value?: boolean) => void): void => {
    dispatch(modalManager.openConfirmationModal(title, message, closeModal));
  };

  const closeModal = (id: string): void => {
    dispatch(modalManager.closeModal(id));
  };

  return { openModal, openConfirmation, closeModal };
};
