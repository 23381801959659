import { UserRole } from 'contracts/enums';
import { NavigationItemMap } from 'contracts/types/routing';
import translate from 'core/utils/helpers/translate';

import { AccountRoutes, DashboardRoutes, MarketRoutes, QuoteRoutes, UsersRoutes, VendorRoutes } from './Routes';

const navigationItemsDef: NavigationItemMap = {
  quote: {
    path: QuoteRoutes.root,
    label: translate('quote.quoteMenuItem'),
    icon: 'quote',
    authorizedUserRoles:[
      UserRole.admin,
      UserRole.configurationUser,
      UserRole.manager,
      UserRole.salesPerson,
      UserRole.bulkPricing,
      UserRole.bulkPricingDetailedView
    ],
    subItems: {
      singleService: {
        path: QuoteRoutes.root + QuoteRoutes.singleService,
        label: translate('quote.singleServiceMenuItem'),
        authorizedUserRoles:[
          UserRole.admin,
          UserRole.configurationUser,
          UserRole.manager,
          UserRole.salesPerson,
          UserRole.bulkPricing,
          UserRole.bulkPricingDetailedView,
          UserRole.rateUploadUser
        ],
      },
      bulkFile: {
        path: QuoteRoutes.root + QuoteRoutes.bulkFile,
        label: translate('quote.bulkFileMenuItem'),
        authorizedUserRoles:[
          UserRole.admin,
          UserRole.bulkPricing,
          UserRole.bulkPricingDetailedView,
        ],
      },
      customQuote: {
        path: QuoteRoutes.root + QuoteRoutes.customQuote,
        label: translate('quote.customQuoteMenuItem'),
        authorizedUserRoles:[
          UserRole.admin,
          UserRole.configurationUser,
          UserRole.manager,
          UserRole.salesPerson
        ],
      },
      promoCode: {
        path: QuoteRoutes.root + QuoteRoutes.promoCode,
        label: translate('quote.promoCodeMenuItem'),
        authorizedUserRoles:[UserRole.admin],
      },
    },
  },
  dashboard: {
    path: DashboardRoutes.root,
    label: translate('dashboard.dashboardMenuItem'),
    icon: 'dashboard',
    authorizedUserRoles:[
      UserRole.admin,
      UserRole.configurationUser,
      UserRole.manager,
      UserRole.salesPerson
    ],
    subItems: {}
  },
  vendor: {
    path: VendorRoutes.root,
    label: translate('vendor.vendorMenuItem'),
    icon: 'vendor',
    authorizedUserRoles:[
      UserRole.admin,
      UserRole.configurationUser,
      UserRole.manager,
      UserRole.salesPerson,
      UserRole.salesIHR,
      UserRole.bulkPricing,
      UserRole.bulkPricingDetailedView,
      UserRole.rateUploadUser
    ],
    subItems: {
      serviceRates: {
        path: VendorRoutes.root + VendorRoutes.serviceRates,
        label: translate('vendor.serviceRatesMenuItem'),
        authorizedUserRoles:[
          UserRole.admin,
          UserRole.configurationUser,
          UserRole.manager,
          UserRole.salesPerson,
          UserRole.bulkPricing,
          UserRole.bulkPricingDetailedView,
          UserRole.rateUploadUser
        ],
      },
      customServiceRates: {
        path: VendorRoutes.root + VendorRoutes.customRates,
        label: translate('vendor.customServiceRatesMenuItem'),
        authorizedUserRoles:[
          UserRole.admin,
          UserRole.configurationUser,
          UserRole.manager,
          UserRole.salesPerson,
          UserRole.salesIHR,
          UserRole.bulkPricing,
          UserRole.bulkPricingDetailedView,
          UserRole.rateUploadUser
        ],
      },
    },
  },
  market: {
    path: MarketRoutes.root,
    label: translate('market.marketMenuItem'),
    icon: 'market',
    authorizedUserRoles:[
      UserRole.admin,
      UserRole.configurationUser,
      UserRole.manager,
      UserRole.salesPerson,
      UserRole.salesIHR,
      UserRole.bulkPricing,
      UserRole.bulkPricingDetailedView,
      UserRole.rateUploadUser
    ],
    subItems: {
      marketAvailability: {
        path: MarketRoutes.root + MarketRoutes.marketAvailability,
        label: translate('market.marketAvailabilityMenuItem'),
        authorizedUserRoles:[
          UserRole.admin,
          UserRole.configurationUser,
          UserRole.manager,
          UserRole.salesPerson,
          UserRole.salesIHR,
          UserRole.bulkPricing,
          UserRole.bulkPricingDetailedView,
          UserRole.rateUploadUser
        ],
      },
      marketConfiguration: {
        path: MarketRoutes.root + MarketRoutes.marketConfiguration,
        label: translate('market.marketConfigMenuItem'),
        authorizedUserRoles: [UserRole.configurationUser],
      },
      marginMinimum: {
        path: MarketRoutes.root + MarketRoutes.marginMinimum,
        label: translate('market.marginMinimuMenuItem'),
        authorizedUserRoles: [UserRole.manager],
      },
      bulkRates: {
        path: MarketRoutes.root + MarketRoutes.bulkRates,
        label: translate('market.bulkRatesMenuItem'),
        authorizedUserRoles: [UserRole.admin, UserRole.rateUploadUser],
      },
    },
  },
  users: {
    path: UsersRoutes.root,
    label: translate('users.usersMenuItem'),
    authorizedUserRoles:[UserRole.admin],
    icon: 'user',
    subItems: {}
  },
  logout: {
    path: AccountRoutes.root + AccountRoutes.logout,
    label: translate('logout'),
    icon: 'logoutCircle',
  }
};

const navigationItems = Object.values(navigationItemsDef);

export default navigationItems;
