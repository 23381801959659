export function downloadBlob(blob: Blob, filename: string): void {
  const winUrl = window.URL || window.webkitURL; // || window.mozURL || window.msURL;
  const data = winUrl.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.download = filename;
  link.href = data;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.remove();
    winUrl.revokeObjectURL(data);
  }, 250);
}
