import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { ApiUrlKey } from 'contracts/enums';
import { UploadRateListFilterDataView } from 'contracts/types/request';
import { ServiceError, UploadRatePagedListDataView } from 'contracts/types/response';
import http, { useQueryMutation, useReactQuery } from 'core/services/http';

export const useGetBulkRateFiles = (
  request: UploadRateListFilterDataView,
  customOnSuccess?: (response: UploadRatePagedListDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
    UseQueryResult<UploadRatePagedListDataView, AxiosError> =>
  useReactQuery(ApiUrlKey.getBulkRatesFiles, async() => {
    const response = await http.post<UploadRatePagedListDataView>(ApiUrlKey.getBulkRatesFiles, {
      params: request });
    return response.data;
  }, customOnSuccess, customOnError);

export const useUploadRateFiles = (
  customOnSuccess: () => void,
  customOnError: (err: AxiosError<ServiceError>) => void,
  onUploadProgress?: (progressEvent: unknown) => void): 
    UseMutationResult<Promise<never>, AxiosError<ServiceError>, FormData> => 
  useQueryMutation(
    ApiUrlKey.uploadBulkRateFile,
    async(request: FormData) => http.post(ApiUrlKey.uploadBulkRateFile,
      request, { onUploadProgress }),
    customOnSuccess, customOnError, false, true);

