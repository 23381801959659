import React, { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { UserRole } from 'contracts/enums';
import RoleRoute from 'routing/RoleRoute';
import { VendorRoutes } from 'routing/Routes';

const ServiceRatesPage = lazy(() => import('vendor/components/pages/ServiceRatesPage'));
const CustomServiceRatesPage = lazy(() => import('vendor/components/pages/CustomServiceRatesPage'));
const CustomServiceRatePage = lazy(() => import('vendor/components/pages/CustomServiceRatePage'));

const VendorRouter = (): JSX.Element => (
  <Routes>
    <Route path={VendorRoutes.serviceRates} element={<ServiceRatesPage />} />
    <Route path={VendorRoutes.customRates} element={
      <RoleRoute
        roles={[UserRole.salesPerson, UserRole.manager, UserRole.salesIHR]}>
        <CustomServiceRatesPage />
      </RoleRoute>
    } />
    <Route path={VendorRoutes.customRate} element={
      <RoleRoute
        roles={[UserRole.salesPerson, UserRole.manager, UserRole.salesIHR]}>
        <CustomServiceRatePage />
      </RoleRoute>
    } />
    {/* Default Route */}
    <Route path="*" element={<Navigate to={VendorRoutes.root + VendorRoutes.customRates} replace />} />
    <Route index element={<Navigate to={VendorRoutes.root + VendorRoutes.customRates} replace/>} />
  </Routes>
);

export default VendorRouter;
