import { combineReducers } from 'redux';

import { reducer as modalManagerReducer } from './modalManager';
import { reducer as notifierReducer } from './notifier';

const coreReducer = combineReducers({
  modalManager: modalManagerReducer,
  notifier: notifierReducer,
});

export default coreReducer;
