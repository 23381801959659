import React, { useEffect } from 'react';

import { ModalComponentProps } from 'contracts/types/modal';
import { Confirmation, ConfirmationDialog, ConfirmationMessage, ConfirmationTitle } from 'core/components/styled/Modal';
import Colors from 'core/styles/colors';
import translate from 'core/utils/helpers/translate';

import { Button } from '../styled/Button';
import { ButtonSet } from '../styled/ButtonSet';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ title, message, closeModal }) => {

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler, false);
    return (() => {
      document.removeEventListener('keydown', keyboardHandler, false);
    }); 
  });
  const keyboardHandler = (event: KeyboardEvent): void => {
    event.preventDefault();
    if (event.code === '27') {
      cancel();
    } else if (event.code === '13') {
      accept();
    }
  };

  const accept = (): void => {
    closeModal(true);
  };

  const cancel = (): void => {
    closeModal(false);
  };

  return (<Confirmation>
    <ConfirmationDialog>
      <ConfirmationTitle>{title}</ConfirmationTitle>
      <ConfirmationMessage>{message}</ConfirmationMessage>
      <ButtonSet align="right">
        <Button line color={Colors.primary} margin="no small no no" onClick={cancel}>
          {translate('no')}
        </Button>

        <Button color={Colors.primary} onClick={accept}>
          {translate('yes')}
        </Button>
      </ButtonSet>
    </ConfirmationDialog>
  </Confirmation>
  );
};

export interface ConfirmationModalProps extends ModalComponentProps {
  title: string;
  message: string;
}

export default ConfirmationModal;
