import styled, { css } from 'styled-components';

import { mapper } from 'utils/styles';

export const COLUMN_SIZES = {
  '1/12': '8.33%',
  '2/12': '16.66%',
  '3/12': '25%',
  '4/12': '33.33%',
  '5/12': '41.66%',
  '6/12': '50%',
  '7/12': '58.33%',
  '8/12': '66.66%',
  '9/12': '75%',
  '10/12': '83.33%',
  '11/12': '91.66%',
  '12/12': '100%',
};
export interface GridColumnProps {
  align?: string;
  size?: string;
  padding?: string;
  width?: string;
  shift?: string;
}

export const GridColumn = styled.div<GridColumnProps>`
  position: relative;
  background-color: #fff;
  text-align: ${props => mapper({ left: 'left', center: 'center', right: 'right' }, props.align, 'left')};
  align-self: ${props =>
    mapper({ left: 'flex-start', center: 'center', right: 'flex-end' }, props.align, 'flex-start')};
  padding: ${props =>
    mapper({ no: 0, small: '15px', medium: '30px', large: '60px', xLarge: '130px' }, props.padding, 'no')};
  

  ${props =>
    props.size &&
    css`
      width: ${mapper(COLUMN_SIZES, props.size)};;
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.shift &&
    css`
      margin-left: ${mapper(COLUMN_SIZES, props.shift)};
    `};
`;

interface GridProps {
  margin?: string;
  padding?: string;
  centered?: boolean;
  multiLine?: boolean;
  multiLineColumn?: boolean
}

export const Grid = styled.div<GridProps>`
  display: flex;
  width: 100%;
  padding: ${props => mapper({ no: 0, small: '10px', medium: '20px', large: '30px' }, props.padding, 'no')};
  
  ${props =>
    props.centered &&
    css`
      justify-content: center;
    `};

  ${props =>
    props.multiLine &&
    css`
      flex-wrap: wrap;
    `};

  ${props =>
    props.multiLineColumn &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `};
`;
