import React, { SVGProps } from 'react';

const renderIcon = (icon: string): JSX.Element | null => {
  switch (icon) {
    
    case 'quote':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M19.118 11.154h-.824c-.227 0-.412-.172-.412-.385 0-.212.185-.384.412-.384h.824c.226 0 .411.172.411.384 0 .213-.185.385-.411.385m0 2.308h-.824c-.227 0-.412-.173-.412-.385 0-.212.185-.385.412-.385h.824c.226 0 .411.173.411.385 0 .212-.185.385-.411.385m0 2.307h-.824c-.227 0-.412-.172-.412-.384 0-.213.185-.385.412-.385h.824c.226 0 .411.172.411.385 0 .212-.185.384-.411.384m0 2.308h-.824c-.227 0-.412-.172-.412-.385 0-.212.185-.384.412-.384h.824c.226 0 .411.172.411.384 0 .213-.185.385-.411.385m-3.294-6.923h-4.942c-.227 0-.411-.172-.411-.385 0-.212.184-.384.411-.384h4.942c.226 0 .411.172.411.384 0 .213-.185.385-.411.385m-5.353 1.923c0-.212.184-.385.411-.385h3.294c.227 0 .412.173.412.385 0 .212-.185.385-.412.385h-3.294c-.227 0-.411-.173-.411-.385M15 15.769h-4.118c-.227 0-.411-.172-.411-.384 0-.213.184-.385.411-.385H15c.226 0 .412.172.412.385 0 .212-.186.384-.412.384m-1.647 2.308h-2.47c-.228 0-.412-.172-.412-.385 0-.212.184-.384.411-.384h2.47c.227 0 .413.172.413.384 0 .213-.186.385-.412.385m7.944-12.195L19.94 7.148l-1.355-1.266a.434.434 0 0 0-.583 0l-1.356 1.266-1.355-1.266a.434.434 0 0 0-.584 0l-1.355 1.266-1.356-1.266a.434.434 0 0 0-.583 0L10.06 7.148 8.703 5.882C8.441 5.639 8 5.814 8 6.154v17.692c0 .342.444.514.703.272l1.356-1.266 1.355 1.266c.162.15.422.15.583 0l1.356-1.266 1.355 1.266c.162.15.422.15.584 0l1.355-1.266 1.356 1.266c.161.15.421.15.583 0l1.355-1.266 1.356 1.266c.258.241.703.07.703-.272V6.154c0-.34-.442-.515-.703-.272"
            fill="currentColor"
          />
        </g>
      );

    case 'dashboard':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M7.083 7h15.834C24.067 7 25 7.895 25 9v12c0 1.105-.933 2-2.083 2H7.083C5.933 23 5 22.105 5 21V9c0-1.105.933-2 2.083-2zm1.319 14.723a.849.849 0 0 0 1.106-.391l1.999-4.157 1.655 1.588a.856.856 0 0 0 .736.222.83.83 0 0 0 .61-.452l1.915-3.982 1.662 2.127a.85.85 0 0 0 .8.31.825.825 0 0 0 .658-.537l2.5-7.198a.793.793 0 0 0-.527-1.012c-.437-.14-.909.087-1.054.507l-1.997 5.75-1.546-1.979a.85.85 0 0 0-.755-.315.832.832 0 0 0-.669.46l-2 4.157-1.654-1.588a.856.856 0 0 0-.736-.222.83.83 0 0 0-.61.453l-2.5 5.198a.787.787 0 0 0 .407 1.061z"
            fill="currentColor"
          />
        </g>
      );

    case 'vendor':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M22.083 19.667c-.23 0-.416.174-.416.389 0 .213.186.388.416.388h2.5c.23 0 .417-.175.417-.388 0-.215-.187-.39-.417-.39h-2.5zm-2.916-3c1.165.282 2.078 1.134 2.381 2.221h3.035c.23 0 .417-.174.417-.388v-3.89c0-1.929-1.683-3.5-3.75-3.5h-1.667c-.23 0-.416.175-.416.39v5.166zM5.417 8C5.187 8 5 8.174 5 8.389v10.889c0 .214.187.389.417.389h.416c0-1.716 1.495-3.111 3.334-3.111 1.838 0 3.333 1.395 3.333 3.11H15c0-1.715 1.495-3.11 3.333-3.11V8.389c0-.215-.186-.389-.416-.389h-12.5zm12.916 9.333c-1.378 0-2.5 1.047-2.5 2.334 0 1.286 1.122 2.333 2.5 2.333 1.379 0 2.5-1.047 2.5-2.333 0-1.287-1.121-2.334-2.5-2.334zm-9.166 0c-1.379 0-2.5 1.047-2.5 2.334 0 1.286 1.121 2.333 2.5 2.333 1.378 0 2.5-1.047 2.5-2.333 0-1.287-1.122-2.334-2.5-2.334z"
            fill="currentColor"
          />
        </g>
      );

    case 'market':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M23 11.136v10.637C23 22.45 22.463 23 21.8 23H8.2c-.663 0-1.2-.55-1.2-1.227V11.136c0-.677.537-1.227 1.2-1.227h13.6c.318 0 .623.13.849.36.225.23.351.542.351.867zm-13.2-4.5c-.22 0-.4-.183-.4-.409C9.4 5.55 9.937 5 10.6 5h8.8c.663 0 1.2.55 1.2 1.227 0 .226-.18.41-.4.41H9.8zm12 2.046c0 .226-.18.409-.4.409H8.6c-.22 0-.4-.183-.4-.41 0-.677.537-1.226 1.2-1.226h11.2c.663 0 1.2.549 1.2 1.227z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      );

    case 'user':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M20 12c0-1.381-.56-2.631-1.464-3.535A4.985 4.985 0 0 0 15 7c-1.381 0-2.631.56-3.536 1.465A4.984 4.984 0 0 0 10 12c0 1.381.56 2.631 1.464 3.535A4.985 4.985 0 0 0 15 17c1.381 0 2.631-.56 3.536-1.465A4.984 4.984 0 0 0 20 12zM9 22c0 1 2.25 2 6 2 3.518 0 6-1 6-2 0-2-2.354-4-6-4-3.75 0-6 2-6 4z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      );

    case 'logoutCircle':
      return (
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="15" cy="15" r="15" />
          <path
            d="M21.642 7.51v14.98a.51.51 0 0 1-.51.51H9.512A.511.511 0 0 1 9 22.49v-6.733h5.23l-1.627 2.182a.689.689 0 0 0 .134.958l.006.006a.688.688 0 0 0 .954-.132l2.499-3.348a.681.681 0 0 0 .134-.411v-.024a.681.681 0 0 0-.134-.41l-2.499-3.349a.688.688 0 0 0-.954-.132l-.006.006a.689.689 0 0 0-.134.958l1.628 2.182H9V7.51c0-.282.23-.51.512-.51h11.62a.51.51 0 0 1 .51.51z"
            fill="currentColor"
          />
        </g>
      );

    case 'email':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M16 16.888L30.682 5.47A2.94 2.94 0 0 0 29.088 5H2.91c-.563 0-1.085.164-1.531.436L16 16.888z"
            fill="currentColor"
          />
          <path
            d="M31.652 6.53L16.428 18.354a.722.722 0 0 1-.864-.001L.378 6.473c-.234.409-.376.87-.378 1.371v15.642c.002 1.567 1.306 2.844 2.91 2.844h26.175c1.604 0 2.909-1.277 2.91-2.844V7.844c0-.476-.13-.92-.343-1.314"
            fill="currentColor"
          />
        </g>
      );

    case 'phone':
      return (
        <path
          d="M30.607 23.223l-3.413-3.413a3.564 3.564 0 0 0-5.043 0l-.914.914a75.797 75.797 0 0 1-9.96-9.962l.914-.914a3.57 3.57 0 0 0 0-5.042L8.778 1.393a3.564 3.564 0 0 0-5.044 0L1.862 3.265a5.19 5.19 0 0 0-.653 6.51 75.081 75.081 0 0 0 21.016 21.017 5.168 5.168 0 0 0 6.51-.654l1.872-1.873a3.562 3.562 0 0 0 0-5.042"
          fill="currentColor"
          fillRule="evenodd"
        />
      );

    case 'information':
      return (
        <path
          d="M14.4 24h3.2v-9.6h-3.2V24zM16 0C7.168 0 0 7.168 0 16s7.168 16 16 16 16-7.168 16-16S24.832 0 16 0zm0 28.8C8.944 28.8 3.2 23.056 3.2 16 3.2 8.944 8.944 3.2 16 3.2c7.056 0 12.8 5.744 12.8 12.8 0 7.056-5.744 12.8-12.8 12.8zm-1.6-17.6h3.2V8h-3.2v3.2z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'edit':
      return (
        <path
          d="M.023 31.299l1.469-4.897A5.815 5.815 0 0 1 2.95 23.96L21.617 5.29a.727.727 0 0 1 1.028 0l4.06 4.062a.727.727 0 0 1 0 1.028L8.038 29.049a5.82 5.82 0 0 1-2.443 1.46L.702 31.977a.546.546 0 0 1-.679-.678zM29.365.637l1.999 1.998c.848.849.848 2.224 0 3.073l-2.436 2.435a.724.724 0 0 1-1.025 0l-4.047-4.047a.725.725 0 0 1 0-1.024L26.292.636a2.172 2.172 0 0 1 3.073 0z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'delete':
      return (
        <path
          d="M26.82 9.332H4.323C3.593 9.332 3 8.735 3 8V6.665c0-.736.592-1.333 1.323-1.333h3.97c0-1.954.407-3.095 1.313-4.008C10.512.41 11.642 0 13.58 0h3.982c1.94 0 3.069.41 3.974 1.324.906.913 1.314 2.054 1.314 4.008h3.97c.73 0 1.323.597 1.323 1.333V8c0 .736-.593 1.333-1.323 1.333zm-7.273-6.004a2.529 2.529 0 0 0-1.988-.662h-3.976a2.529 2.529 0 0 0-1.987.662 2.578 2.578 0 0 0-.656 2.004h9.263a2.578 2.578 0 0 0-.656-2.004zm5.943 7.338a.66.66 0 0 1 .483.21.67.67 0 0 1 .18.498l-1.168 18.75A1.996 1.996 0 0 1 22.997 32H8.144a1.996 1.996 0 0 1-1.988-1.875L4.988 11.374a.67.67 0 0 1 .18-.499.66.66 0 0 1 .483-.21H25.49z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'download':
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M25.332 14.667l-4 .001V.667A.668.668 0 0 0 20.665 0h-9.333c-.367 0-.667.3-.667.667v14h-4c-.586 0-.89.717-.472 1.138l9.334 9.334c.25.249.693.249.944 0l9.333-9.334a.668.668 0 0 0-.472-1.138"
            fill="currentColor"
          />
          <path
            d="M30.667 23.325c-.738 0-1.334.596-1.334 1.334v4.666H2.667V24.66a1.333 1.333 0 1 0-2.667 0v6c0 .736.596 1.333 1.333 1.333h29.334c.736 0 1.333-.597 1.333-1.333v-6c0-.738-.597-1.334-1.333-1.334"
            fill="currentColor"
          />
        </g>
      );

    case 'send':
      return (
        <path
          d="M.015 29.43l31.987-13.715L.015 2 0 12.667l22.858 3.048L0 18.763z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'open':
      return (
        <path
          d="M28.444 28.444H3.556V3.556H16V0H3.556A3.555 3.555 0 0 0 0 3.556v24.888A3.555 3.555 0 0 0 3.556 32h24.888A3.555 3.555 0 0 0 32 28.444V16h-3.556v12.444zM19.556 0v3.556h6.373L8.453 21.03l2.516 2.516L28.444 6.07v6.373H32V0H19.556z"
          fill="currentColor"
          fillRule="nonzero"
        />
      );

    case 'arrowDown':
      return (
        <path
          stroke="currentColor"
          strokeWidth="4.655"
          d="M30.364 9.636L15.938 22.364 2.364 9.636"
          fill="none"
          fillRule="evenodd"
        />
      );

    case 'close':
      return (
        <g stroke="currentColor" strokeWidth="5" fill="none" fillRule="evenodd" strokeLinecap="square">
          <path d="M28 4L4 28M28 28L4 4" />
        </g>
      );

    case 'back':
      return (
        <path
          d="M7.6 14L18.1 3.5a1 1 0 0 0-.007-1.407L16.707.707a1.003 1.003 0 0 0-1.415.001L0 16l15.292 15.292c.391.391 1.03.387 1.415.001l1.386-1.386c.39-.39.384-1.03.006-1.408L7.6 18h23.398A1 1 0 0 0 32 16.99v-1.98c0-.558-.444-1.01-1.002-1.01H7.6z"
          fill="currentColor"
          fillRule="evenodd"
        />
      );

    case 'copy':
      return (
        <path
          d="M12.8421053,3 L5.26315789,3 C4.56842105,3 4,3.57272727 4,4.27272727 L4,13.1818182 L5.26315789,13.1818182 L5.26315789,4.27272727 L12.8421053,4.27272727 L12.8421053,3 Z M12.2105263,5.54545455 L16,9.36363636 L16,15.7272727 C16,16.4272727 15.4315789,17 14.7368421,17 L7.78315789,17 C7.08842105,17 6.52631579,16.4272727 6.52631579,15.7272727 L6.53263158,6.81818182 C6.53263158,6.11818182 7.09473684,5.54545455 7.78947368,5.54545455 L12.2105263,5.54545455 Z M11.5789474,10 L15.0526316,10 L11.5789474,6.5 L11.5789474,10 Z"
          id="Shape"
          fill="currentColor"
          fillRule="evenodd"
        />
      );

    case 'search':
      return (
        <g
          transform="translate(1 1)"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <path d="M29 29l-7.733-7.733" />
          <circle cx="12.429" cy="12.429" r="12.429" />
        </g>
      );

    default:
      return null;
  }
};

const Icon: React.FC<ComponentProps> = ({ className, icon }) => (
  <svg viewBox="0 0 32 32" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" className={className}>
    {renderIcon(icon)}
  </svg>
);

interface OwnProps {
  icon: string;
  small?: boolean;
}
type ComponentProps = OwnProps & SVGProps<any>;

export default Icon;
