import { DefaultTheme, ThemedStyledProps } from 'styled-components';

import Colors from 'core/styles/colors';

export interface ColorProps {
  color?: Colors;
}

const COLOR_MAP = {
  [Colors.default]: 'brandDefault',
  [Colors.primary]: 'brandPrimary',
  [Colors.secondary]: 'brandSecondary',
  [Colors.success]: 'brandSuccess',
  [Colors.info]: 'brandInfo',
  [Colors.warning]: 'brandWarning',
  [Colors.alert]: 'brandAlert',
};

const getKeyValue = <T extends Record<string, string>, U extends keyof T>(key: U) => (obj: T) =>
  obj[key];
  
const colorMapper = (
  props: ThemedStyledProps<ColorProps, DefaultTheme>,
  defaultColor = Colors.default): string => getKeyValue(
  COLOR_MAP[props.color || defaultColor])(props.theme.colors);

export default colorMapper;
