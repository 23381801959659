import styled, { css, LoadingStyledComponentProps } from 'styled-components';

import { customLoadingOverlay } from 'core/styles/customLoading';
import { mapper } from 'utils/styles';

export const ButtonSetSection = styled.div<LoadingStyledComponentProps>`
  text-align: center;
`;

export const ButtonSetExplain = styled.div`
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 16px;
`;

export const ButtonSetRow = styled.div`
  display: flex;
  flex-basis: 80%;
  justify-content: space-evenly;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface ButtonSetProps extends LoadingStyledComponentProps {
  align?: string,
  margin?: string,
  marginTop?: string
}
export const ButtonSet = styled.div<ButtonSetProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 30px;

  ${props =>
    props.isLoading && 
    css`
      ${customLoadingOverlay(26)};
    `};


  ${props =>
    props.align &&
    css`
      justify-content: ${mapper({ left: 'flex-start', center: 'center', right: 'flex-end' }, props.align)};
    `};

  ${props =>
    props.margin &&
    css`
      margin: ${mapper({ no: '0px', small: '15px', medium: '30px', large: '45px', xLarge: '60px' }, props.margin)};
    `};

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};
`;
