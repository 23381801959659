import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fontFamilyBase: 'Ars Maquette, Helvetica Neue, Helvetica, sans-serif',
  fontSizeBase: '14px',

  fontWheightSmall: 200,
  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  colors: {
    bodyBackground: '#fafcfc',
    
    black: '#000000',
    grayBase: '#242833',
    grayDarker: '#76768c',
    grayDark: '#b4b4c4',
    gray: '#d9d9e1',
    grayLight: '#f2f2f5',
    grayLighter: '#fafafc',
    grayText: '#929292',

    brandDefault: '#191b18',
    brandPrimary: '#009d90',
    brandSecondary: '#aeafad',
    brandSuccess: '#009d90',
    brandInfo: '#26c6da',
    brandWarning: '#ffc400',
    brandWhite: '#ffffff',
    brandAlert: '#d42727',
    brandRed: '#E53834',
  },
};

export default theme;
