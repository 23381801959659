import { Environments } from 'contracts/enums';

export const BASE_URL_MAP: { [url: string]: Environments } = {
  'quicksale.rubicon.com': Environments.production,
  'quicksale.qa.aws.rubicon.com': Environments.qa,
  'quicksale.dev.aws.rubicon.com': Environments.development,
};

export const API_BASE_URL_MAP = {
  [Environments.production]: 'https://sales-api.prod.aws.rubicon.com',
  [Environments.qa]: 'https://sales-api.qa.aws.rubicon.com',
  [Environments.development]: 'https://sales-api.dev.aws.rubicon.com',
  [Environments.local]: 'https://sales-api.dev.aws.rubicon.com'
};
