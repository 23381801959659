import styled, { css, LoadingStyledComponentProps } from 'styled-components';

import { loadingOverlay } from 'core/styles';

export const CartSummary = styled.div<LoadingStyledComponentProps>`
  margin-bottom: 20px;

  ${props =>
    props.isLoading && 
    css`
      ${loadingOverlay(26)};
    `};
`;

export const CartSummaryLocation = styled.div`
  margin-bottom: 30px;
`;

export const CartSummaryLocationAddress = styled.h4`
  margin-bottom: 15px;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightNormal};
  font-size: 16px;
`;
