import React, { useState } from 'react';

import { FieldError, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { queryClient } from 'App';
import { ApiUrlKey } from 'contracts/enums';
import { UploadBulkFileFormData } from 'contracts/types/form';
import { ModalComponentProps } from 'contracts/types/modal';
import FileSelect from 'core/components/HookForm/FileSelect';
import { Button } from 'core/components/styled/Button';
import { ButtonSet } from 'core/components/styled/ButtonSet';
import { Modal, ModalDialog, ModalClose, ModalTitle } from 'core/components/styled/Modal';
import { createErrorNotificationMessage, createSuccessNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/utils/helpers/translate';
import { useUploadRateFiles } from 'market/services/marketBulkRates';

const UploadQuoteFileModal: React.FC<ModalComponentProps> = ({ closeModal }) => {

  const dispatch = useDispatch();

  const { setValue, handleSubmit } = useForm<UploadBulkFileFormData>({
    mode: 'onBlur'
  });
  
  const [file, setFile] = useState<File | undefined>();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);

  const { isLoading: isUploadingFile, mutate: doUploadFile } = useUploadRateFiles(() => {
    dispatch(createSuccessNotificationMessage(translate('quote.fileWasUploadedSuccessfully')));
    queryClient.invalidateQueries(ApiUrlKey.getBulkRatesFiles);
    closeModal();
  }, error => {
    if (error.response?.status === 400) {
      dispatch(createErrorNotificationMessage(translate('quote.invalidFile')));
    } else 
      dispatch(createErrorNotificationMessage(translate('quote.thereWasAProblemWithTheUploadedFile')));
  });

  const onFileUploaded = (file: File): void => {
    setValue('files', file);
    setFile(file);
  };

  const onSubmit = ({ files }: UploadBulkFileFormData): void => {
    setIsSubmited(true);
    if (files) {
      const request = new FormData();
      request.append('files', files);
      doUploadFile(request);
    }
  };

  return (<Modal isLoading={isUploadingFile}>
    <ModalDialog>
      <ModalClose onClick={() => closeModal()} />
      <ModalTitle>{translate('quote.uploadFile')}</ModalTitle>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FileSelect
          onFileUploaded={onFileUploaded}
          errors={!file && isSubmited ? ({ message: translate('validator.requiredField') } as FieldError) : undefined }
        />
        <ButtonSet>
          <Button type="submit">{translate('quote.upload')}</Button>
        </ButtonSet>
      </form>
    </ModalDialog>
  </Modal>
  );
};

export default UploadQuoteFileModal;
