import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ApplicationState } from 'contracts/types/state';
import { deleteNotificationMessage } from 'core/ducks/notifier';
import Colors from 'core/styles/colors';

import {
  NotificationList as NotificationListContainer,
  Notification as NotificationContainer,
  NotificationClose,
  NotificationCloseIcon,
} from './styled/NotificationList';
import TextComponent from './styled/TextComponent';

const Notification: React.FC<NotificationProps> = ({ id, messages, color, onNotificationClose }) => { 

  return (
    <NotificationContainer color={color}>
      <NotificationClose onClick={() => onNotificationClose(id)}>
        <NotificationCloseIcon />
      </NotificationClose>
      {messages.map((text, index) => (
        <TextComponent key={index} block>
          {text}
        </TextComponent>
      ))}
    </NotificationContainer>);
};

interface NotificationProps {
  id: string,
  messages: string[],
  color?: Colors;
  onNotificationClose: (id: string) => void;
}

const NotificationList: React.FC = () => {
  const dispatch = useDispatch();

  const notifications = useSelector((state: ApplicationState) => state.core.notifier.notifications);

  const onNotificationClose = (id: string): void => {
    if (id) {
      dispatch(deleteNotificationMessage(id));
    }
  };

  const displayNotifications: JSX.Element[] = [];
  notifications.forEach(notification => {
    displayNotifications.push(
      <CSSTransition
        key={notification.id}
        classNames='notifier-'
        timeout={{ enter: 500, exit: 300 }}
      >
        <Notification
          id={notification.id}
          key={notification.id}
          messages={notification.messages}
          color={notification.color}
          onNotificationClose={() => onNotificationClose(notification.id)}
        />
      </CSSTransition>,
    );
    if (!notification.timer) {
      notification.timer = setTimeout(() => {
        onNotificationClose(notification.id);
      }, notification.duration || 6000);
    }
  });

  return (
    <NotificationListContainer>
      <TransitionGroup className='notifier'>
        {displayNotifications}
      </TransitionGroup>
    </NotificationListContainer>
  );
};

export default NotificationList;
