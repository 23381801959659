import React from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ServiceUnavailableReasonFormData } from 'contracts/types/form';
import { ModalComponentProps } from 'contracts/types/modal';
import { ExceptionQuoteDataView } from 'contracts/types/response';
import Input from 'core/components/HookForm/Input';
import { Button } from 'core/components/styled/Button';
import { ButtonSet } from 'core/components/styled/ButtonSet';
import { Modal, ModalClose, ModalDialog, ModalTitle } from 'core/components/styled/Modal';
import { createErrorNotificationMessage, createSuccessNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/utils/helpers/translate';
import { useServiceUnavailable } from 'quote/services/customQuote';
import { VendorRoutes } from 'routing/Routes';
import { useNavigate } from 'react-router';

const ServiceUnavailableReasonModal: React.FC<ServiceUnavailableReasonModalProps> = ({ customQuote, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { register, handleSubmit, formState: { errors } } = useForm<ServiceUnavailableReasonFormData>({
    mode: 'onBlur'
  });
  
  const { isLoading, mutate: doServiceUnavailable } = useServiceUnavailable(() => {
    dispatch(createSuccessNotificationMessage(translate('quote.customQuoteMarkedAsUnavailable'), 3000));
    closeModal();
    navigate(VendorRoutes.root + VendorRoutes.customRates);
  }, () => {
    dispatch(createErrorNotificationMessage(translate('quote.thereWasAnErrorSavingTheCustomQuote'), 3000));
  });
  
  const markServiceAsUnavailable = ({ reason }: ServiceUnavailableReasonFormData): void => {
    if (customQuote.id) {
      doServiceUnavailable({
        quoteId: customQuote.id,
        reason
      });
    }
  };

  return (
    <Modal size="small" isLoading={isLoading}>
      <ModalDialog>
        <ModalClose onClick={() => closeModal()} />
        <ModalTitle center>{translate('quote.serviceUnavailableReason')}</ModalTitle>
        <p> {translate('quote.serviceUnavailableLabel')}</p>
        <form onSubmit={handleSubmit(markServiceAsUnavailable)} noValidate>
          <Input
            type='text'
            {...register('reason', { required: { value: true, message: translate('validator.requiredField') } })}
            errors={errors.reason}
          />
          <ButtonSet>
            <Button type="submit">{translate('submitBtnLabel')}</Button>
          </ButtonSet>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export interface ServiceUnavailableReasonModalProps extends ModalComponentProps {
  customQuote: ExceptionQuoteDataView;
}

export default ServiceUnavailableReasonModal;
