import React, { PropsWithChildren } from 'react';

import NavigationBar from './NavigationBar/NavigationBar';
import { Page as PageContainer, PageContent } from './styled/Page';

const Page: React.FC<PropsWithChildren> = ({ children }) => {

  // TODO: to check if this can be removed and controlled only through user role guard
  // const isSalesIHR = login.selectors.isSalesIHR();
  // const isManager = login.selectors.isManager();
  // const hasCheckoutPage = login.selectors.hasIndependentCheckoutPage();

  // componentDidUpdate(prevProps) {
  //   if (this.props.location !== prevProps.location) {
  //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  //   }
  // }

  return (
    <PageContainer>
      <NavigationBar
        // isSalesIHR={isSalesIHR}
        // isManager={isManager}
        // hasCheckoutPage={hasCheckoutPage}
      />
      <PageContent>{children}</PageContent>
    </PageContainer>
  );
};

export default Page;
