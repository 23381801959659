import styled, { css } from 'styled-components';

interface FormErrorProps {
  center?: boolean;
}
const FormError = styled.span<FormErrorProps>`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${props => props.theme.colors.brandAlert};

  ${props =>
    props.center &&
    css`
      text-align:center;
    `}
`;

export const FormRequired = styled.span`
  font-size: 12px;
  position: absolute;
  left: -10px;
  top: 50%;
  color: ${props => props.theme.colors.brandAlert};
`;

export default FormError;
