import React, { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router';

import AccountAuthRoute from 'routing/AccountAuthRoute';
import GuestRoute from 'routing/GuestRoute';
import { AccountRoutes } from 'routing/Routes';

const LoginPage = lazy(() => import('account/components/pages/LoginPage'));
const LogoutPage = lazy(() => import('account/components/pages/LogoutPage'));
const ResetPasswordPage = lazy(() => import('account/components/pages/ResetPasswordPage'));
const PasswordResetEmailSentPage = lazy(() => import('account/components/pages/PasswordResetEmailSentPage'));
const RequestPasswordResetPage = lazy(() => import('account/components/pages/RequestPasswordResetPage'));

const AccountRouter: React.FC = () => (
  <Routes>
    <Route element={<AccountAuthRoute />}>
      {/* Routes for logged in user */}
      <Route path={AccountRoutes.logout} element={<LogoutPage />} />
      <Route path={AccountRoutes.expiredPassword} element={<ResetPasswordPage />} />
    </Route>
    <Route path={AccountRoutes.login} element={<GuestRoute><LoginPage /></GuestRoute>} />
    <Route path={AccountRoutes.forgotPassword} element={<GuestRoute><RequestPasswordResetPage /></GuestRoute>} />
    <Route path={AccountRoutes.passwordResetEmailSent}
      element={<GuestRoute><PasswordResetEmailSentPage /></GuestRoute>} />
    <Route path={AccountRoutes.resetPassword} element={<GuestRoute><ResetPasswordPage /></GuestRoute>} />
    <Route path={AccountRoutes.setPassword} element={<GuestRoute><ResetPasswordPage /></GuestRoute>} />
    {/* Default Route */}
    <Route path="*" element={<Navigate to={AccountRoutes.root + AccountRoutes.login} replace />} />
    <Route index element={<Navigate to={AccountRoutes.root + AccountRoutes.login} replace/>} />
  </Routes>
);

export default AccountRouter;
