import { AxiosError } from 'axios';
import update from 'immutability-helper';
import isArray from 'lodash-es/isArray';

import { NotificationType } from 'contracts/enums';
import { NotifierAction } from 'contracts/types/action';
import { ServiceError } from 'contracts/types/response';
import { NotifierState, ReduceFunctionMap } from 'contracts/types/state';
import Colors from 'core/styles/colors';
import formatServiceError from 'core/utils/helpers/formatServiceError';
import uuid from 'core/utils/helpers/uuid';
import { getReducerBuilder } from 'core/utils/reducer/reducerBuilder';

export const DEFAULT_AUTO_CLOSE_TIME = 6000;

// Actions Keys
const ROOT_KEY = 'core';
enum ActionKey {
  NOTIFICATION_CREATE = 'core/NOTIFICATION_CREATE',
  NOTIFICATION_DELETE = 'core/NOTIFICATION_DELETE',
}

// Initial State
const getInitialState: () => NotifierState = () => {
  return {
    notifications: [],
  };
};

// Reducer
const reducerKeys = [
  ActionKey.NOTIFICATION_CREATE,
  ActionKey.NOTIFICATION_DELETE,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  NotifierState,
  NotifierAction
> = {
  [ActionKey.NOTIFICATION_CREATE]: (state, action) => {
    const {
      notificationType,
      notificationMessages,
      notificationColor,
      notificationDuration
    } = action;
    if (!notificationType || !notificationMessages) {
      return state;
    }
    return update(state, {
      notifications: {
        $push: [
          {
            id: uuid(),
            type: notificationType,
            messages: notificationMessages,
            color: notificationColor,
            duration: notificationDuration
          },
        ],
      },
    });
  },
  [ActionKey.NOTIFICATION_DELETE]: (state, action) => {
    const { notificationId } = action;
    if (!action.notificationId) {
      return state;
    }
    const index = state.notifications.findIndex(
      notification => notification.id === notificationId,
    );
    if (index < 0) {
      return state;
    }
    return update(state, {
      notifications: { $splice: [[index, 1]] },
    });
  },
};

export const reducer = getReducerBuilder<NotifierState, NotifierAction>(
  ROOT_KEY,
  getInitialState,
)
  .withReduceFunctionMap(reducerFunctionMap)
  .buildReducer();

// Actions
const createNotification = (
  notificationType: NotificationType,
  notificationMessages: string[],
  notificationColor?: Colors,
  notificationDuration?: number
): NotifierAction => ({
  type: ActionKey.NOTIFICATION_CREATE,
  notificationType,
  notificationMessages,
  notificationColor,
  notificationDuration
});

export const createNotificationMessage = (
  notificationMessage: string | string[], notificationDuration?: number): NotifierAction =>
  createNotification(NotificationType.info,
    isArray(notificationMessage) ? notificationMessage : [notificationMessage],
    Colors.default, notificationDuration);

export const createSuccessNotificationMessage = (
  notificationMessage: string | string[], notificationDuration: number = DEFAULT_AUTO_CLOSE_TIME): NotifierAction =>
  createNotification(NotificationType.success,
    isArray(notificationMessage) ? notificationMessage : [notificationMessage],
    Colors.success, notificationDuration);

export const createWarningNotificationMessage = (
  notificationMessage: string | string[], notificationDuration?: number): NotifierAction =>
  createNotification(NotificationType.warning,
    isArray(notificationMessage) ? notificationMessage : [notificationMessage],
    Colors.warning, notificationDuration);
      
export const createErrorNotificationMessage = (
  notificationMessage: string | string[], notificationDuration?: number): NotifierAction =>
  createNotification(NotificationType.error,
    isArray(notificationMessage) ? notificationMessage : [notificationMessage],
    Colors.alert, notificationDuration);

export const createServiceErrorNotificationMessage = (error: AxiosError<ServiceError>): NotifierAction =>
  createNotification(NotificationType.error,
    [formatServiceError(error)],
    Colors.alert);

export const deleteNotificationMessage = (notificationId: string): NotifierAction => ({
  type: ActionKey.NOTIFICATION_DELETE,
  notificationId,
});
