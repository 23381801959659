import React, { useState } from 'react';

import { FieldError } from 'react-hook-form';

import translate from 'core/utils/helpers/translate';

import Error from './Error';
import {
  FileSelect as FileSelectContainer, FileSelectButton,
  FileSelectFileName, FileSelectIcon, FileSelectInput, FileSelectText
} from './styled/FileSelect';
import { FormGroup } from './styled/FormGroup';

const FileSelect = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({ name, onFileUploaded, errors }, ref) => {
    const [file, setFile] = useState<File | undefined>(undefined);

    const onDragOver: React.DragEventHandler<HTMLDivElement> = event => event.preventDefault();

    const onFilesDropped: React.DragEventHandler<HTMLDivElement> = event => {
      if (event.dataTransfer.files.length) selectFiles(event.dataTransfer.files);
      event.preventDefault();
    };

    const onFilesSelected: React.ChangeEventHandler<HTMLInputElement> = event => {
      if (event.target.files && event.target.files.length) selectFiles(event.target.files);
    };

    const selectFiles = (files: FileList): void => {
      onFileUploaded(files[0]);
      setFile(files[0]);
    };

    return (
      <FormGroup>
        <FileSelectContainer onDragOver={onDragOver} onDrop={onFilesDropped}>
          <FileSelectIcon />
          <FileSelectText>{translate('quote.dragAndDrop')}</FileSelectText>
          <FileSelectText>
            {translate('or')}
            <FileSelectButton>
              <FileSelectInput name={name} ref={ref} onChange={onFilesSelected} /> {translate('quote.chooseFile')}
            </FileSelectButton>
          </FileSelectText>

          {file ? (
            <FileSelectFileName>
              {file.name}
            </FileSelectFileName>
          ) : null}
        </FileSelectContainer>

        {errors ? <Error error={errors} /> : null}
      </FormGroup>
    );
  });

interface OwnProps {
  onFileUploaded: (file: File) => void;
  errors?: FieldError;
  multiple?: boolean;
}

type ComponentProps = OwnProps & React.InputHTMLAttributes<HTMLInputElement>;

export default FileSelect;
