import React, { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { UserRole } from 'contracts/enums';
import RoleRoute from 'routing/RoleRoute';
import { DashboardRoutes, MarketRoutes } from 'routing/Routes';

const MarketAvailabilityPage = lazy(() => import('market/components/pages/MarketAvailability'));
const MarketConfigurationPage = lazy(() => import('market/components/pages/MarketConfigurationPage'));
const MarketMinimumPage = lazy(() => import('market/components/pages/MarketMarginPage'));
const MarketBulkRates = lazy(() => import('market/components/pages/MarketBulkRatesPage'));

const MarketRouter = (): JSX.Element => (
  <Routes>
    <Route path={MarketRoutes.marketConfiguration} element={
      <RoleRoute
        roles={[
          UserRole.admin,
          UserRole.configurationUser]}
        redirectTo={DashboardRoutes.root}>
        <MarketConfigurationPage />
      </RoleRoute>
    } />
    <Route path={MarketRoutes.marginMinimum} element={
      <RoleRoute
        roles={[
          UserRole.admin,
          UserRole.manager]}
        redirectTo={DashboardRoutes.root}>
        <MarketMinimumPage />
      </RoleRoute>
    } />
    <Route path={MarketRoutes.bulkRates} element={
      <RoleRoute
        roles={[
          UserRole.admin,
          UserRole.rateUploadUser]}
        redirectTo={MarketRoutes.marketAvailability}>
        <MarketBulkRates />
      </RoleRoute>
    } />
    <Route path={MarketRoutes.marketAvailability} element={<MarketAvailabilityPage />} />
    {/* Default Route */}
    <Route path="*" element={<Navigate to={MarketRoutes.root + MarketRoutes.marketAvailability} replace />} />
    <Route index element={<Navigate to={MarketRoutes.root + MarketRoutes.marketAvailability} replace/>} />
  </Routes>
  
);

export default MarketRouter;

