import React, { useEffect, useState } from 'react';

import isNumber from 'lodash-es/isNumber';
import { FieldError } from 'react-hook-form';

import Error from './Error';
import { FormGroup } from './styled/FormGroup';
import { FieldDescription, FormLabel } from './styled/FormLabel';
import { Input as FormInput } from './styled/Input';

const Input = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({ label, fieldDescription, isHidden, errors, withSelectStyle, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    useEffect(() => {
      setHasValue(isNumber(props.defaultValue) || !!props.defaultValue);
    }, [props.defaultValue, setHasValue]);

    return (
      <FormGroup isFocused={isFocused} hasValue={hasValue}>
        {label && <FormLabel>{label}</FormLabel>}
        <FormInput
          {...props}
          ref={ref}
          withSelectStyle={withSelectStyle}
          isHidden={isHidden}
          onFocus={() => setIsFocused(true)}
          onBlur={e => {
            setIsFocused(false);
            setHasValue(!!e.target.value);
          }}
        />
        {errors && <Error error={errors} />}
        {fieldDescription && (
          <FieldDescription>{fieldDescription}</FieldDescription>
        )}
      </FormGroup>
    );
  },
);

interface OwnProps {
  label?: string;
  fieldDescription?: string;
  isHidden?: boolean;
  errors?: FieldError;
  withSelectStyle?: boolean;
}

type ComponentProps = OwnProps & React.InputHTMLAttributes<HTMLInputElement>;

export default Input;
