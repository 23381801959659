import React from 'react';

import { useForm } from 'react-hook-form';

import { SalesForceContactDataViewExtended } from 'contracts/types/component';
import { ContactFormData } from 'contracts/types/form';
import { ModalComponentProps } from 'contracts/types/modal';
import { SearchExistingAccountsDataView, ShoppingCartCustomerRecipientContactDataView } from 'contracts/types/request';
import Input from 'core/components/HookForm/Input';
import { Button } from 'core/components/styled/Button';
import { ButtonSet } from 'core/components/styled/ButtonSet';
import { Modal, ModalClose, ModalDialog, ModalTitle } from 'core/components/styled/Modal';
import { useSearchShoppingCartContact } from 'core/services/smbPresale';
import translate from 'core/utils/helpers/translate';
import { isEmail, isOptionalPhone } from 'core/utils/helpers/validator';

const ContactFormModal: React.FC<ContactFormModalProps> = 
({ contactIndex, contact, onContactFormSubmit, closeModal }) => {
  const updateContact = contactIndex !== undefined;

  const { handleSubmit, register, getValues, formState: { errors } } = useForm<ContactFormData>({
    mode: 'onBlur',
  });

  const { isLoading: isLoadingSearchShoppingCartContact,
    mutate: doSearchShoppingCartContact } = 
      useSearchShoppingCartContact(data => {
        if (data && data.contacts.length) {
          const { email, phone } = getValues();
          onContactFormSubmit(
            { email, phone, custId: data.contacts[0].gusCustId } as SalesForceContactDataViewExtended, contactIndex);
          closeModal();
        }
      });
      
  const onSubmit = (formData: ContactFormData): void => {
    const contactEmails: SearchExistingAccountsDataView = {
      contacts: [{ email: formData.email } as ShoppingCartCustomerRecipientContactDataView]
    };
    doSearchShoppingCartContact(contactEmails);
  };

  return (
    <Modal size="small" isLoading={isLoadingSearchShoppingCartContact}>
      <ModalDialog>
        <ModalClose onClick={() => closeModal()} />
        <ModalTitle>{updateContact ? translate('service.updateContact') : translate('service.addContact')}</ModalTitle>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Input
            label={translate('email')}
            errors={errors.email}
            defaultValue={contact ? contact.email : undefined}
            {...register('email', { required: { value: true, message: translate('validator.requiredField') }, validate: email => isEmail(email) })}
          />
          <Input
            label={translate('phone')}
            errors={errors.phone}
            defaultValue={contact ? contact.phone : undefined}
            {...register('phone', { validate: phone => isOptionalPhone(phone) })}
          />
          <ButtonSet>
            <Button>{updateContact ? translate('service.updateContact') : translate('service.addContact')}</Button>
          </ButtonSet>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export interface ContactFormModalProps extends ModalComponentProps {
  contactIndex?: number;
  contact?: SalesForceContactDataViewExtended;
  onContactFormSubmit: (contact: SalesForceContactDataViewExtended, contactIndex?: number) => void;
}

export default ContactFormModal;
