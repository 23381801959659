export enum ApiUrlKey {
  // Account
  login = 'sales/login',
  resetForgotPassword = 'sales/resetforgotpassword',
  forgotPassword = 'sales/forgotpassword',
  tokenLogin = 'exception/token',
  resetExpiredPassword = 'sales/changepassword',
  // Quote
  getQuotes = 'pricequote/search',
  getQuoteDownloadLink = 'pricequote/determine/download',
  uploadBulkFile = 'pricequote/upload',
  getPromoCodes = 'promocode/get-all',
  addPromoCode = 'promocode/add',
  updatePromoCode = 'promocode/update',
  getCustomQuotes = 'exception/quotes',
  customQuote = 'exception/quote',
  serviceUnavailable = 'exception/quote/unavailable',
  searchVendorDetails = 'exception/quote/vendor/search',
  provideQuotePrice = 'exception/quote/price',
  searchAddress = 'exception/address/search',
  bestmatchAddress = 'exception/address/bestmatch',
  // Vendor Cost
  vendorCost = 'presale/vendor/cost',
  // Dashboard
  getShoppingCarts = 'savings/shoppingcart/list',
  loadProposalCart = 'proposal/preview',
  loadShoppingCart = 'savings/shoppingcart/open',
  openForSaleShoppingCartAsSalesPerson = 'savings/shoppingcart/open/sale',
  getShoppingCartDocumentsById = 'shoppingcart/electronicdocuments',
  downloadProposalCart = 'proposal/download',
  createProposalCart = 'proposal/createcart', // deprecated?
  deleteProposalCart = 'proposal/delete',
  deleteShoppingCart = 'savings/shoppingcart/invalidate',
  resendShoppingCart = 'savings/shoppingcart/resend',
  // Market
  getMinimumMargin = 'margin',
  updateMinimumMargin = 'margin',
  getBulkRatesFiles = 'bulk-rate/search',
  uploadBulkRateFile = 'bulk-rate/upload',
  getBulkDownloadLink = 'bulk-rate',
  getMarketAvailabilityZipCodes = 'marketconfig/zip-map-availability',
  getMarketConfigByZipCode = 'marketconfig/setup',
  updateMarketConfig = 'marketconfig/setup',
  // Users
  loadUsers = 'salesportal/users',
  loadRoles = 'salesportal/roles',
  addSalesUser = 'salesportal/user',
  updateSalesUser = 'salesportal/user',
  deleteSalesUser = 'salesportal/user',
  userCallbackURL = 'account/set-password',
  // Smb Presale
  allowCheck = 'sales/allowCheck',
  marketconfigAddresses = 'marketconfig/addresses',
  searchShoppingCartContact = 'savings/shoppingcart/searchContacts',
  determineAddressandZipRule = 'presale/market-config',
  determineAllRules = 'sale/market-config/all',
  loadBusinessTypes = 'customer/businesstype',
  determineAddressesAndZipsRules = 'presale/markets-configs',
  getPresaleCustomerByOpportunity = 'presale/customer/open/byopportunity',
  postSalesForceQuickQuote = 'presale/site/quote',
  updateProposal = 'presale/proposal/update',
  previewProposal = 'presale/proposal/preview',
  openProposal = 'presale/proposal/open',
  createCart = 'presale/cart/create',
  proposalCart = 'presale/proposal/create',
  presalePrice = 'presale/price',
  saleLimi = 'sale/limit'
}

type QueryUrlPagedKey = [ApiUrlKey, number, number];
type QueryUrlFlaggedKey = [ApiUrlKey, string];
type QueryUrlFlaggedPagedKey = [ApiUrlKey, string, number];
export type QueryUrlKey = ApiUrlKey | QueryUrlPagedKey | QueryUrlFlaggedKey | QueryUrlFlaggedPagedKey;
