import styled from 'styled-components';

export const FileSelectIcon = styled.div`
  width: 32px;
  height: 32px;
  margin-bottom: 13px;
  background: center no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAyOTc4QiIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMjIuOTU3IDkuNzAxTDE5LjMzMiA5Ljd2MTIuNjg5YzAgLjMzMi0uMjcuNjA0LS42MDQuNjA0SDEwLjI3YS42MDYuNjA2IDAgMCAxLS42MDUtLjYwNFY5LjdINi4wNGEuNjA2LjYwNiAwIDAgMS0uNDI3LTEuMDMyTDE0LjA3LjIxMWEuNjI1LjYyNSAwIDAgMSAuODU2IDBsOC40NTggOC40NThhLjYwNS42MDUgMCAwIDEtLjQyOCAxLjAzMk0yNy43OTIgMjRjLS42NjkgMC0xLjIwOS41NC0xLjIwOSAxLjIwOHY0LjIzSDIuNDE3di00LjIzYTEuMjA4IDEuMjA4IDAgMSAwLTIuNDE3IDB2NS40MzhjMCAuNjY3LjU0IDEuMjA4IDEuMjA4IDEuMjA4aDI2LjU4NGMuNjY3IDAgMS4yMDgtLjU0MSAxLjIwOC0xLjIwOHYtNS40MzhDMjkgMjQuNTQgMjguNDU5IDI0IDI3Ljc5MiAyNCIvPjwvZz48L3N2Zz4=);
`;

export const FileSelectText = styled.span`
  margin-bottom: 6px;
  text-align: center;
`;

export const FileSelectFileName = styled.span`
  display: block;
  margin-top: 30px;
`;

export const FileSelectInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

export const FileSelectButton = styled.label`
  cursor: pointer;
  color: ${props => props.theme.colors.brandPrimary};
`;

export const FileSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 290px;
  border-radius: 10px;
  border: 2px dashed ${props => props.theme.colors.brandPrimary};
`;
