import React from 'react';

import { useLocation } from 'react-router';

import { MainNavigationBar, MainNavigationLogo, NavigationBarContainer } from 'routing/styled/NavigationBar';

import NavigationPrimaryItems from './NavigationPrimaryItems';
import NavigationSecondaryItems from './NavigationSecondaryItems';

const NavigationBar: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <NavigationBarContainer>
      <MainNavigationBar>
        <MainNavigationLogo />
        <NavigationPrimaryItems />
      </MainNavigationBar>
      <NavigationSecondaryItems pathname={pathname} />
    </NavigationBarContainer>
  );
};

export default NavigationBar;
