import React, { memo } from 'react';

import navigationItems from 'routing/navigationItems';
import RoleGuard from 'routing/RoleGuard';
import { MainNavigationItem, MainNavigationItemIcon } from 'routing/styled/NavigationBar';

const NavigationPrimaryItems: React.FC = () => (
  <>
    {navigationItems
      .filter(
        entry =>
          entry.path &&
            entry.label &&
            entry.icon
      )
      .map(item => (
        <RoleGuard key={item.path} roles={item.authorizedUserRoles}>
          <MainNavigationItem 
            to={item.path}>
            {item.icon && <MainNavigationItemIcon icon={item.icon} />}
            {item.label}
          </MainNavigationItem>
        </RoleGuard>
      ))}
  </>
);

export default memo(NavigationPrimaryItems);
