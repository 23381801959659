import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { ApiUrlKey } from 'contracts/enums';
import { SavingsDiscountPromoCodeFormData, SavingsUpdateDiscountPromoCodeFormData } from 'contracts/types/form';
import { SavingsDiscountPromoCodeDataView, ServiceError } from 'contracts/types/response';
import http, { useQueryMutation, useReactQuery } from 'core/services/http';

export const useGetPromoCodes = (
  customOnSuccess?: (response: SavingsDiscountPromoCodeDataView[]) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
    UseQueryResult<SavingsDiscountPromoCodeDataView[], AxiosError> =>
  useReactQuery(ApiUrlKey.getPromoCodes, async() => {
    const response = await http.get<SavingsDiscountPromoCodeDataView[]>(ApiUrlKey.getPromoCodes);
    return response.data;
  }, customOnSuccess, customOnError);

export const useAddPromoCode = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
    UseMutationResult<Promise<never>, AxiosError<ServiceError>, SavingsDiscountPromoCodeFormData> =>
  useQueryMutation(
    ApiUrlKey.addPromoCode,
    (request: SavingsDiscountPromoCodeFormData) => http.post(ApiUrlKey.addPromoCode, request),
    customOnSuccess,
    customOnError);

export const useUpdatePromoCode = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void):
    UseMutationResult<Promise<never>, AxiosError<ServiceError>, SavingsUpdateDiscountPromoCodeFormData> =>
  useQueryMutation(
    ApiUrlKey.updatePromoCode,
    (request: SavingsUpdateDiscountPromoCodeFormData) => http.put(ApiUrlKey.updatePromoCode, request),
    customOnSuccess,
    customOnError);
