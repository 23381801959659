import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Outlet, Path, useLocation } from 'react-router';

import account from 'account/ducks/account';
import { ApplicationState } from 'contracts/types/state';
import PageLoading from 'core/components/styled/PageLoading';

import Page from './Page';
import { AccountRoutes } from './Routes';

const AuthRoute: React.FC = () => {
  const location = useLocation();

  const isLoggedIn = account.selectors.isLoggedIn();
  const isExpiredPassword = location.pathname === AccountRoutes.root + AccountRoutes.expiredPassword;
  const isOnLoginPage = location.pathname === AccountRoutes.root + AccountRoutes.login;
  const userClaims = useSelector(
    (state: ApplicationState) => (state.account.user)
  );
  const onlyUpdatePasswordAccess = userClaims?.isPasswordExpired || false;
  const path: Path = { pathname: location.pathname, search: location.search, hash: location.hash };

  if (isLoggedIn && onlyUpdatePasswordAccess) {
    return isExpiredPassword ? (
      <Page><Suspense fallback={<PageLoading />}><Outlet /></Suspense></Page>
    ) : (
      <Navigate to={AccountRoutes.root + AccountRoutes.expiredPassword} state={path} />
    );
  }
  return isLoggedIn && !onlyUpdatePasswordAccess ? (
    <Page><Suspense fallback={<PageLoading />}><Outlet /></Suspense></Page>
  ) : !isLoggedIn && isOnLoginPage ? (
    <Page><Suspense fallback={<PageLoading />}><Outlet /></Suspense></Page>
  ) : (
    <Navigate to={AccountRoutes.root + AccountRoutes.login} state={path}/>
  );
};

export default AuthRoute;
