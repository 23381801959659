import React from 'react';

import { ShoppingCartStatus } from 'contracts/enums';
import { ShoppingCartCustomerProfileRecipientSimpleDataView } from 'contracts/types/response';

import {
  ShoppingCartContactList as ShoppingCartContactListContainer,
  ShoppingCartContact,
  ShoppingCartContactDetail,
  ShoppingCartContactStatus,
} from '../styled/ShoppingCartContactList';

const ShoppingCartContactList: React.FC<ComponentProps> = ({ contacts, margin }) => (
  <ShoppingCartContactListContainer margin={margin}>
    {contacts.map((contact, index) => (
      <ShoppingCartContact key={index}>
        <ShoppingCartContactStatus sold={contact.status === ShoppingCartStatus.sold}>
          {contact.status}
        </ShoppingCartContactStatus>
        <ShoppingCartContactDetail>{contact.email}</ShoppingCartContactDetail>
        {contact.phone && <ShoppingCartContactDetail secondary>{contact.phone}</ShoppingCartContactDetail>}
      </ShoppingCartContact>
    ))}
  </ShoppingCartContactListContainer>
);

interface ComponentProps {
  contacts: ShoppingCartCustomerProfileRecipientSimpleDataView[];
  margin?: string;
}

export default ShoppingCartContactList;
