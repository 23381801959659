import React from 'react';

import { DashboardCartLocationDataView, SaleMarketConfigDataView, ShoppingCartLocationDataView } from 'contracts/types/response';
import { Input as FormInput } from 'core/components/styled/Input';
import translate from 'core/utils/helpers/translate';

import ServiceDetails from './ServiceDetails';
import { CartSummary as CartSummaryContainer, CartSummaryLocation, CartSummaryLocationAddress } from './styled/CartSummary';

const CartSummary: React.FC<ComponentProps> = ({
  locations,
  allRules,
  salesForceOpportunityId,
  showActions,
  isLoading,
  editService,
  removeService,
  updateServiceNotes,
}) => {

  const saveNotes = (selectedOpId: string, input: React.ChangeEvent<HTMLInputElement>): void => {
    if (updateServiceNotes) {
      const notes = input.target ? input.target.value : undefined;
      updateServiceNotes(selectedOpId, notes);
    }
  };

  return (
    <CartSummaryContainer isLoading={isLoading}>
      {locations.map((location, locationIndex) => (
        <CartSummaryLocation key={locationIndex}>
          <CartSummaryLocationAddress>{location.address.line1}</CartSummaryLocationAddress>
          {location.services.map((service, serviceIndex) => {
            return (
              <div key={serviceIndex}>
                <ServiceDetails
                  location={location}
                  service={service}
                  allRules={allRules}
                  isSelected={!!salesForceOpportunityId && service.salesForceOpportunityId === salesForceOpportunityId}
                  showActions={showActions}
                  editService={editService}
                  removeService={removeService}
                />
                {showActions ? (
                  <FormInput
                    name="notes"
                    placeholder={translate('notes')}
                    type="text"
                    onChange={input => saveNotes(service.salesForceOpportunityId, input)}
                  />
                ) : null}
              </div>
            );
          })}
        </CartSummaryLocation>
      ))}
    </CartSummaryContainer>
  );
};

interface ComponentProps {
  locations: ShoppingCartLocationDataView[] | DashboardCartLocationDataView[];
  allRules: SaleMarketConfigDataView | undefined;
  salesForceOpportunityId?: string;
  showActions?: boolean;
  isLoading?: boolean;
  updateServiceNotes?: (selectedOpId: string, nodes?: string) => void;
  editService?: (salesForceSiteId: string, salesForceOpportunityId: string) => void;
  removeService?: (salesForceSiteId: string, salesForceOpportunityId: string) => void;
}

export default CartSummary;
