import React from 'react';

import { FieldError } from 'react-hook-form';

import { OptionType } from 'contracts/types/form';

import Error from './Error';
import { FormGroup } from './styled/FormGroup';
import { FieldDescription } from './styled/FormLabel';
import { Select as FormSelect } from './styled/Select';

const Select = React.forwardRef<HTMLSelectElement, ComponentProps>(
  ({ label, selectFieldLabel, isQuotePage, options, errors, value, disabled, ...rest }, ref) => {
    return (
      <FormGroup isDisabled={disabled}>
        {selectFieldLabel && (
          <FieldDescription>{selectFieldLabel}</FieldDescription>
        )}
        <FormSelect ref={ref as any} {...rest} isEmpty={!value}>
          <option value=''>{label}</option>
          {options.map((option: OptionType, index: number) => {
            const selectValue = isQuotePage ? (option.order || option.code) : option.code;
            return (
              <option value={selectValue} key={index}>
                {option.label}
              </option>
            );
          })}
        </FormSelect>
        {errors && <Error error={errors} />}
      </FormGroup>
    );
  },
);

interface OwnProps {
  label?: string;
  selectFieldLabel?: string;
  isQuotePage?: boolean;
  errors?: FieldError;
  options: OptionType[];
}

type ComponentProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  OwnProps;

export default Select;
