/* eslint-disable @typescript-eslint/no-explicit-any */
import http from 'core/services/http';
import { getSessionAuthToken } from 'core/utils/helpers/session';
import { AccountRoutes } from 'routing/Routes';

const requestInterceptor = (config: any): any => {
  const authToken = getSessionAuthToken();
  if (authToken) {
    config.headers.Authorization = authToken;
  }
  return config;
};

const requestErrorInterceptor = (error: any): Promise<never> => Promise.reject(error);

const responseIncerceptor = (config: any): any => config;

const responseErrorInterceptor = (error: any): Promise<never> => {
  if (error.response.status === 401) {
    window.location.href = AccountRoutes.root + AccountRoutes.logout;
  }

  return Promise.reject(error);
};

const registerAuthInterceptor = (): void => {
  http.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor,
  );
  http.interceptors.response.use(
    responseIncerceptor,
    responseErrorInterceptor,
  );
};

export default registerAuthInterceptor;
