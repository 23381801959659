import React, { useState } from 'react';

import moment from 'moment';
import DatePickerInput from 'react-day-picker/DayPickerInput';
import { FieldError } from 'react-hook-form';

import { EDTDate } from 'core/utils/helpers/formatter';

import Error from './Error';
import DatePickerContainer from './styled/DatePicker';
import { FormGroup } from './styled/FormGroup';

const dateFormat = 'MM/DD/YYYY';

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
  ({ label, defaultDate, onDaySelect, errors, ...props }, ref) => {
    const [day, setDay] = useState(defaultDate ? moment(defaultDate).format(dateFormat) : undefined);
    
    const onDayChange = (day: Date): void => {
      const date = EDTDate(day);
      onDaySelect(date);
      setDay(date);
    };

    return (
      <FormGroup>
        <DatePickerContainer>
          <DatePickerInput
            onDayChange={onDayChange}
            value={day}
            placeholder={label}
            format={dateFormat}
            ref={ref as any}
            {...props}
          />
        </DatePickerContainer>
        {errors && <Error error={errors} />}
      </FormGroup>
    );
  });

  interface DatePickerProps {
  onDaySelect: (day: string) => void,
  label: string,
  defaultDate?: string;
  errors?: FieldError;
}

export default DatePicker;
