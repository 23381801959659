import every from 'lodash-es/every';
import has from 'lodash-es/has';
import isArray from 'lodash-es/isArray';
import isNumber from 'lodash-es/isNumber';
import isString from 'lodash-es/isString';
import size from 'lodash-es/size';
import validator from 'validator';

import { linesOfTextToArray } from './normalizer';
import translate from './translate';

const fullNameRegex = new RegExp(/^\w+\s\w+$/);
const phoneRegex = new RegExp(/^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/);
const zipCodeRegex = new RegExp(/^\d{5}$/);

export const isValidNumber = (value: string | number): string | undefined => {
  const numVal = isNumber(value) ? value : parseInt(value);
  return isNumber(numVal) ? undefined : translate('validator.pleaseProvideANumber');
};

export const isPositiveNumber = (value: string | number): string | undefined => {
  const numVal = isNumber(value) ? value : parseInt(value);
  return isNumber(numVal) && numVal >= 0 ? undefined : translate('validator.pleaseProvideAPositiveNumber');
};
 
export const isOptionalPositiveNumber = (value: string | number): string | undefined => 
  (!value ? undefined : isPositiveNumber(value));

export const isPhone = (value: string): string | undefined => (phoneRegex.test(value) ? undefined : translate('validator.pleaseProvideValidPhoneNumber'));
export const isEmail = (value: string): string | undefined => (validator.isEmail(value) ? undefined : translate('validator.pleaseProvideValidEmailAddress'));
export const isZipCode = (value: string): string | undefined => (zipCodeRegex.test(value) ? undefined : translate('validator.pleaseProvideValidZipCode'));

export const isZipCodeList = (value: string): string | undefined => {
  const zipCodeList = linesOfTextToArray(value);
  return isArray(zipCodeList) && size(zipCodeList) && every(zipCodeList, zipCode => zipCodeRegex.test(zipCode))
    ? undefined
    : translate('validator.pleaseProvideValidZipCodeList');
};

export const isOptionalPhone = (value: string): string | undefined => (!value ? undefined : isPhone(value));

export const isFullName = (value: string): string | undefined =>
  fullNameRegex.test(value) ? undefined : translate('pleaseProvideANameWithFirstAndLastName');

export const isGreaterThan = (value: string | number | undefined, minimumValue: number): string | undefined => {
  const numVal = isNumber(value) ? value : isString(value) ? parseInt(value) : 0;
  return isNumber(numVal) && numVal >= minimumValue ? undefined : `${translate('validator.mustBeHigherThan')} ${minimumValue}`;
};

export const hasCoordinates = (value: any): string | undefined =>
  has(value, 'latitude') && has(value, translate('longitude'))
    ? undefined
    : translate('validator.pleaseSelectAnAddressWithCoordinates');

export const hasCountry = (value: any): string | undefined =>
  has(value, 'country') && !!value.country ? undefined : translate('validator.pleaseSelectAnAddressWithACountry');
export const hasState = (value: any): string | undefined =>
  has(value, 'state') && !!value.state ? undefined : translate('validator.pleaseSelectAnAddressWithAState');
export const hasCity = (value: any): string | undefined =>
  has(value, 'city') && !!value.city ? undefined : translate('validator.pleaseSelectAnAddressWithACity');
export const hasZip = (value: any): string | undefined =>
  has(value, 'zip') && !!value.zip ? undefined : translate('validator.pleaseSelectAnAddressWithAZipCode');

export const hasStreet = (value: any): string | undefined =>
  has(value, 'street') && !!value.street ? undefined : translate('validator.pleaseSelectAnAddressWithAStreet');

export const hasStreetNumber = (value: any): string | undefined =>
  has(value, 'streetNumber') && !!value.streetNumber ? undefined : translate('validator.pleaseSelectAnAddressWithAStreetNumber');

const maxLength = (max: any) => (value: string): string | undefined =>
  value && value.length > max ? `${translate('validator.mustBe')} ${max} ${translate('validator.charactersOrLess')}` : undefined;

export const maxLength1000 = maxLength(1000);
