import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';

import { SaleUserFormData } from 'contracts/types/form';
import { RoleNameDataView } from 'contracts/types/response';
import Checkbox from 'core/components/HookForm/Checkbox';
import Input from 'core/components/HookForm/Input';
import FormError from 'core/components/HookForm/styled/FormError';
import { Button } from 'core/components/styled/Button';
import { ButtonSet } from 'core/components/styled/ButtonSet';
import { Grid, GridColumn } from 'core/components/styled/Grid';
import translate from 'core/utils/helpers/translate';

import UserGroupTypeSelect from '../UserGroupTypeSelect';

const UserEditorForm: React.FC<OwnProps> = ({ user, roles, onSubmit }) => {
    
  const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm<SaleUserFormData>();
  const watchRoles = watch('roles');

  useEffect(() => {
    if (user && roles) {
      const userRoles: string[] = [];
      roles.forEach((role: RoleNameDataView) => {
        const userRole = user.roles.find(x => x === role.name);
        if (userRole) userRoles.push(userRole);
      });
      setValue('salesUserGroupId', user.salesUserGroupId);
      setValue(`roles`, userRoles);
      setValue('email', user.email);
    }
  }, [user, roles, setValue]);

  const onGroupSelect = (groupId: number): void => {
    setValue('salesUserGroupId', groupId);
  };

  const onFormSubmit = (formValues: SaleUserFormData): void => {
    watchRoles && watchRoles.find(x => x) && onSubmit(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <Grid multiLineColumn>
        <GridColumn size="12/12">
          <UserGroupTypeSelect
            salesUserGroupId={user ? user.salesUserGroupId : undefined}
            onGroupSelect={onGroupSelect}
            placeHolder={translate('users.userGroup')}
            { ...register('salesUserGroupId', 
              { required: { value: true, message: translate('validator.requiredField') }
              }) }
            errors={errors.salesUserGroupId}
          />
        </GridColumn>
        {roles && roles.map((role: RoleNameDataView) => {
          return <Checkbox
            value={role.name}
            key={roles.findIndex((x: RoleNameDataView) => x.name === role.name)}
            margin="small no no no"
            label={role.name}
            { ...register(`roles.${roles.findIndex((x: RoleNameDataView) => x.name === role.name)}`,)}
          />;
        }
        )}
        {watchRoles && !watchRoles.find(x => x) &&
          <FormError>{translate('users.youMustSelectAtLeastOneUserRole')}</FormError>
        }
        <GridColumn size="12/12" padding="medium no no no">
          <Input
            label={translate('Email')}
            defaultValue={user ? user.email : undefined}
            { ...register('email', 
              { required: { value: true, message: translate('validator.requiredField') }, 
                validate: val => isEmail(val) || `${translate('validator.pleaseProvideValidEmailAddress')}`
              }) }
            errors={errors.email}
            disabled={!!user}
          />
        </GridColumn>
        <GridColumn align="center">
          <ButtonSet>
            <Button type='submit'>{translate('users.saveUser')}</Button>
          </ButtonSet>
        </GridColumn>
      </Grid>
    </form>
  );
  
};

  interface OwnProps {
    onSubmit: (formValues: SaleUserFormData) => void,
    user?: SaleUserFormData,
    userIndex?: number,
    roles?: RoleNameDataView[]
  }

export default UserEditorForm;
