import React, { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { QuoteRoutes } from 'routing/Routes';

const QuotedFilesPage = lazy(() => import('quote/components/pages/QuotedFilesPage'));
const PromoCodePage = lazy(() => import('quote/components/pages/PromoCodePage'));
const SingleServiceQuotePage = lazy(() => import('quote/components/pages/SingleServiceQuotePage'));
const CustomQuotesDashboardPage = lazy(() => import('quote/components/pages/CustomQuotesDashboardPage'));
const ViewCustomQuotePage = lazy(() => import('quote/components/pages/ViewCustomQuotePage'));
const RequestCustomQuotePage = lazy(() => import('quote/components/pages/RequestCustomQuotePage'));

const QuoteRouter = (): JSX.Element => (
  <Routes>
    <Route path={QuoteRoutes.singleService} element={<SingleServiceQuotePage />} />
    <Route path={QuoteRoutes.singleServiceWithId} element={<SingleServiceQuotePage />} />
    <Route path={QuoteRoutes.bulkFile} element={<QuotedFilesPage />} />
    <Route path={QuoteRoutes.promoCode} element={<PromoCodePage />} />
    <Route path={QuoteRoutes.customQuote} element={<CustomQuotesDashboardPage />} />
    <Route path={QuoteRoutes.viewCustomQuote} element={<ViewCustomQuotePage />} />
    <Route path={QuoteRoutes.createCustomQuote} element={<RequestCustomQuotePage />} />
    {/* Default Route */}
    <Route path="*" element={<Navigate to={QuoteRoutes.root + QuoteRoutes.singleService} replace />} />
    <Route index element={<Navigate to={QuoteRoutes.root + QuoteRoutes.singleService} replace/>} />
  </Routes>
);

export default QuoteRouter;
