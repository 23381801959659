import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { ApiUrlKey } from 'contracts/enums';
import { MarketConfigAddressDataView, MarketConfigListAddressDataView, PresaleRetailPricesDataView, SearchExistingAccountsDataView } from 'contracts/types/request';
import { AllowCheckDataView, BusinessIndustryDataView, CartLimitations, ListSaleMarketConfigDataView, MarketPlaceMutlipleAddressesRulesDataView, PresaleCartDataView, PresaleCartResultDataView, PresaleRetailPricesResponseDataView, SaleMarketConfigDataView, SearchExistingAccountsResponseDataView, ServiceError } from 'contracts/types/response';
import { MARKET_CONFIG_QUERY_STALE_TIME } from 'core/constants/queryStaleTime';
import http, { useQueryMutation, useReactQuery } from 'core/services/http';

// Addresses
export const useMarketconfigAddresses = (
  customOnSuccess?: (response: MarketPlaceMutlipleAddressesRulesDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
      UseMutationResult<MarketPlaceMutlipleAddressesRulesDataView,
      AxiosError<ServiceError>,
      MarketConfigListAddressDataView> => 
  useQueryMutation(
    ApiUrlKey.marketconfigAddresses,
    async(request: MarketConfigListAddressDataView) => {
      const response = await http.post<MarketPlaceMutlipleAddressesRulesDataView>(
        ApiUrlKey.marketconfigAddresses,
        request);
      return response.data;
    }, customOnSuccess, customOnError);

// Contacts
export const useSearchShoppingCartContact = (
  customOnSuccess?: (response: SearchExistingAccountsResponseDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
      UseMutationResult<SearchExistingAccountsResponseDataView,
      AxiosError<ServiceError>,
      SearchExistingAccountsDataView> => 
  useQueryMutation(
    ApiUrlKey.searchShoppingCartContact,
    async(request: SearchExistingAccountsDataView) => {
      const response = await http.post<SearchExistingAccountsResponseDataView>(
        ApiUrlKey.searchShoppingCartContact,
        request);
      return response.data;
    }, customOnSuccess, customOnError);

// Presale
export const useAllowCheck = (
  customOnSuccess?: (response: AllowCheckDataView) => void): 
          UseMutationResult<AllowCheckDataView,
          AxiosError<ServiceError>> => 
  useQueryMutation(
    ApiUrlKey.allowCheck,
    async() => {
      const response = await http.get<AllowCheckDataView>(ApiUrlKey.allowCheck);
      return response.data;
    }, customOnSuccess);

export const useDetermineAddressandZipRule = (
  customOnSuccess?: (response: SaleMarketConfigDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
          UseMutationResult<SaleMarketConfigDataView,
          AxiosError<ServiceError>,
          MarketConfigAddressDataView> => 
  useQueryMutation(
    ApiUrlKey.determineAddressandZipRule,
    async(request: MarketConfigAddressDataView) => {
      const response = await http.post<SaleMarketConfigDataView>(
        ApiUrlKey.determineAddressandZipRule, request);
      return response.data;
    }, customOnSuccess, customOnError);

export const useDetermineAddressesAndZipsRules = (
  customOnSuccess?: (response: ListSaleMarketConfigDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
              UseMutationResult<ListSaleMarketConfigDataView,
              AxiosError<ServiceError>,
              MarketConfigListAddressDataView> => 
  useQueryMutation(
    ApiUrlKey.determineAddressesAndZipsRules,
    async(request: MarketConfigListAddressDataView) => {
      const response = await http.post<ListSaleMarketConfigDataView>(
        ApiUrlKey.determineAddressesAndZipsRules, request);
      return response.data;
    }, customOnSuccess, customOnError);

export const useDetermineAllRules = (
  customOnSuccess?: (response: SaleMarketConfigDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
          UseQueryResult<SaleMarketConfigDataView, AxiosError> => 
  useReactQuery(ApiUrlKey.determineAllRules, async() => {
    const response = await http.post<SaleMarketConfigDataView>(ApiUrlKey.determineAllRules);
    return response.data;
  }, customOnSuccess, customOnError, true, true, {
    staleTime: MARKET_CONFIG_QUERY_STALE_TIME
  });

export const useLoadBusinessTypes = (
  customOnSuccess?: (response: BusinessIndustryDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
            UseQueryResult<BusinessIndustryDataView, AxiosError> => 
  useReactQuery(ApiUrlKey.loadBusinessTypes, async() => {
    const response = await http.get<BusinessIndustryDataView>(ApiUrlKey.loadBusinessTypes);
    return response.data;
  }, customOnSuccess, customOnError, true, true, {
    staleTime: MARKET_CONFIG_QUERY_STALE_TIME
  });

export const useGetPresaleCustomerByOpportunity = (
  customOnSuccess?: (response: PresaleCartDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
          UseMutationResult<PresaleCartDataView,
          AxiosError<ServiceError>,
          string> => 
  useQueryMutation(
    ApiUrlKey.getPresaleCustomerByOpportunity,
    async(opportunityId: string) => {
      const response = await http.get<PresaleCartDataView>(
        ApiUrlKey.getPresaleCustomerByOpportunity + `/${opportunityId}`);
      return response.data;
    }, customOnSuccess, customOnError);

export const usePostSalesForceQuickQuote = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
                                  UseMutationResult<unknown,
                                  AxiosError<ServiceError>,
                                  PresaleCartDataView> => 
  useQueryMutation(
    ApiUrlKey.postSalesForceQuickQuote,
    async(request: PresaleCartDataView) => {
      const response = await http.post(
        ApiUrlKey.postSalesForceQuickQuote, request);
      return response.data;
    }, customOnSuccess, customOnError);
        
export const useUpdateProposal = (
  customOnSuccess?: (response: PresaleCartDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
                      UseMutationResult<PresaleCartDataView,
                      AxiosError<ServiceError>,
                      {proposalId: number, presale: PresaleCartDataView}> => 
  useQueryMutation(
    ApiUrlKey.updateProposal,
    async(request: {proposalId: number, presale: PresaleCartDataView}) => {
      const response = await http.put<PresaleCartDataView>(
        ApiUrlKey.updateProposal + `/${request.proposalId}`, request.presale);
      return response.data;
    }, customOnSuccess, customOnError);

export const usePreviewProposal = (
  customOnSuccess?: (response: PresaleCartDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
                  UseMutationResult<PresaleCartDataView,
                  AxiosError<ServiceError>,
                  number> => 
  useQueryMutation(
    ApiUrlKey.previewProposal,
    async(proposalId: number) => {
      const response = await http.get<PresaleCartDataView>(
        ApiUrlKey.previewProposal + `/${proposalId}`);
      return response.data;
    }, customOnSuccess, customOnError);

export const useOpenProposal = (
  customOnSuccess?: (response: PresaleCartDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
              UseMutationResult<PresaleCartDataView,
              AxiosError<ServiceError>,
              number> => 
  useQueryMutation(
    ApiUrlKey.openProposal,
    async(proposalId: number) => {
      const response = await http.get<PresaleCartDataView>(
        ApiUrlKey.openProposal + `/${proposalId}`);
      return response.data;
    }, customOnSuccess, customOnError);

export const useCreateCart = (
  customOnSuccess?: (response: PresaleCartResultDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
                  UseMutationResult<PresaleCartResultDataView,
                  AxiosError<ServiceError>,
                  PresaleCartDataView> => 
  useQueryMutation(
    ApiUrlKey.createCart,
    async(request: PresaleCartDataView) => {
      const response = await http.post<PresaleCartResultDataView>(
        ApiUrlKey.createCart, request);
      return response.data;
    }, customOnSuccess, customOnError);

export const useProposalCart = (
  customOnSuccess?: () => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
                      UseMutationResult<unknown,
                      AxiosError<ServiceError>,
                      PresaleCartDataView> => 
  useQueryMutation(
    ApiUrlKey.proposalCart,
    async(request: PresaleCartDataView) => {
      const response = await http.post(
        ApiUrlKey.proposalCart, request);
      return response.data;
    }, customOnSuccess, customOnError);

export const usePresalePrice = (
  customOnSuccess?: (response: PresaleRetailPricesResponseDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
                          UseMutationResult<PresaleRetailPricesResponseDataView,
                          AxiosError<ServiceError>,
                          PresaleRetailPricesDataView> => 
  useQueryMutation(
    ApiUrlKey.presalePrice,
    async(request: PresaleRetailPricesDataView) => {
      const response = await http.post<PresaleRetailPricesResponseDataView>(
        ApiUrlKey.presalePrice, request);
      return response.data;
    }, customOnSuccess, customOnError, false);

// Cart Limitations 

export const useCartLimitations = (
  customOnSuccess?: (response: CartLimitations) => void): 
          UseMutationResult<CartLimitations,
          AxiosError<ServiceError>> => 
  useQueryMutation(
    ApiUrlKey.saleLimi,
    async() => {
      const response = await http.get<CartLimitations>(ApiUrlKey.saleLimi);
      return response.data;
    }, customOnSuccess);
