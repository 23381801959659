import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { mapper } from 'utils/styles';

interface ShoppingCartContactDetailProps {
  secondary?: boolean;
}
export const ShoppingCartContactDetail = styled.span<ShoppingCartContactDetailProps>`
  vertical-align: middle;
  margin-right: 10px;
  line-height: 18px;

  ${props =>
    props.secondary &&
    css`
      font-size: 13px;
      color: ${transparentize(0.4, '#000')};
    `};
`;

interface ShoppingCartContactStatusProps {
  sold?: boolean;
}
export const ShoppingCartContactStatus = styled.span<ShoppingCartContactStatusProps>`
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  margin-right: 10px;
  padding: 3px 0;
  background-color: ${transparentize(0.9, '#000')};
  border-radius: 4px;
  text-align: center;
  font-size: 10px;

  ${props =>
    props.sold &&
    css`
      background-color: ${props => props.theme.colors.brandSuccess};
      color: #fff;
    `};
`;

export const ShoppingCartContact = styled.li`
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface ShoppingCartContactListProps {
  margin?: string;
}
export const ShoppingCartContactList = styled.ul<ShoppingCartContactListProps>`
  list-style: none;
  margin: 10px 0;

  ${props =>
    props.margin &&
    css`
      margin: ${mapper({ no: '0', small: '15px', medium: '30px', large: '45px' }, props.margin)};
    `};
`;
