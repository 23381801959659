import React from 'react';

import { FieldError } from 'react-hook-form';

import { Checkbox as CheckboxContainer, CheckboxCheck, CheckboxInput, CheckboxText } from 'core/components/HookForm/styled/Checkbox';

import Error from './Error';

const Checkbox = React.forwardRef<HTMLInputElement, ComponentProps>(
  ({ label, margin, disabled, errors, ...props }, ref) => {

    return (
      <CheckboxContainer margin={margin} isDisabled={disabled} noLabel={!label}>
        <CheckboxInput {...props} type="checkbox" disabled={disabled} ref={ref} />
        <CheckboxCheck />
        <CheckboxText>{label}</CheckboxText>
        {errors && <Error error={errors} />}
      </CheckboxContainer>
    );
  }
);

interface OwnProps {
  margin?: string;
  label?: string;
  errors?: FieldError;
}

type ComponentProps = React.InputHTMLAttributes<HTMLInputElement> & OwnProps;

export default Checkbox;
