import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { queryClient } from 'App';
import { ApiUrlKey } from 'contracts/enums';
import { SavingsDiscountPromoCodeFormData, SavingsUpdateDiscountPromoCodeFormData } from 'contracts/types/form';
import { ModalComponentProps } from 'contracts/types/modal';
import DatePicker from 'core/components/HookForm/DatePicker';
import Input from 'core/components/HookForm/Input';
import { Button } from 'core/components/styled/Button';
import { ButtonSet } from 'core/components/styled/ButtonSet';
import { Grid, GridColumn } from 'core/components/styled/Grid';
import { Modal, ModalDialog, ModalClose, ModalTitle } from 'core/components/styled/Modal';
import { createSuccessNotificationMessage } from 'core/ducks/notifier';
import translate from 'core/utils/helpers/translate';
import { useAddPromoCode, useUpdatePromoCode } from 'quote/services/promoCode';

const PromoCodeModal: React.FC<PromoCodeModalProps> = ({ promo, closeModal, promoId }) => {

  const { register, setValue, handleSubmit, formState: { errors } } = useForm<SavingsDiscountPromoCodeFormData>({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();

  const { isLoading: isLoadingAddPromoCodeModal, mutate: doAddPromoCode } = 
  useAddPromoCode(() => {
    dispatch(createSuccessNotificationMessage(translate('quote.promoCodeSuccessfullyCreated')));
    queryClient.invalidateQueries(ApiUrlKey.getPromoCodes);
    closeModal();
  });

  const { mutate: doUpdatePromoCode } = 
  useUpdatePromoCode(() => {
    dispatch(createSuccessNotificationMessage(translate('quote.promoCodeSuccessfullyUpdated')));
    queryClient.invalidateQueries(ApiUrlKey.updatePromoCode);
    closeModal();
  });
  useEffect(() => {
    if (promo) {
      setValue('code', promo.code);
      setValue('discount', promo.discount);
      setValue('expireDate', promo.expireDate);
    }
  }, [promo, setValue]);
  const onDaySelect = (day: string): void => {
    setValue('expireDate', day);
  };

  const onSubmit = (formData: SavingsDiscountPromoCodeFormData): void => {
    if (!promoId) {
      const request: SavingsDiscountPromoCodeFormData = {
        code: formData.code,
        discount: formData.discount,
        expireDate: formData.expireDate
      };
      doAddPromoCode(request);
    } else {
      const request: SavingsUpdateDiscountPromoCodeFormData = {
        id: promoId,
        expireDate: formData.expireDate
      };
      doUpdatePromoCode(request);
    }
  };
  
  return (
    <Modal size='small' isLoading={isLoadingAddPromoCodeModal}>
      <ModalDialog>
        <ModalClose onClick={() => closeModal()} />
        <ModalTitle capitalize center>{promo ? translate('quote.editPromoCode') : translate('quote.newPromoCode')}</ModalTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate >
          <Grid multiLineColumn>
            <GridColumn size="12/12" padding="no no xLarge no"> 

              <Input
                label={translate('quote.code')}
                defaultValue={promo?.code}
                type='text'
                {...register('code', { required: { value: true, message: translate('validator.requiredField') } })}
                errors={errors.code}
                disabled={!!promo}
              />
              <Input
                label={translate('amount')}
                defaultValue={promo?.discount}
                type='text'
                {...register('discount', { required: { value: true, message: translate('validator.requiredField') } })}
                errors={errors.discount}
                disabled={!!promo}
              />
              <DatePicker
                label={translate('quote.expireDate')}
                defaultDate={promo?.expireDate}
                onDaySelect={onDaySelect}
                {...register('expireDate', { required: { value: true, message: translate('validator.requiredField') } })}            
              />
            </GridColumn>
            
            <GridColumn align='center'>
              <ButtonSet>
                <Button type="submit">{translate('save')}</Button>
              </ButtonSet>

            </GridColumn>
          </Grid>
        </form>
      </ModalDialog>
    </Modal>
  );
};
export interface PromoCodeModalProps extends ModalComponentProps {
  promo?: SavingsDiscountPromoCodeFormData;
  promoId?: number;
}

export default PromoCodeModal;
