import translate from 'core/utils/helpers/translate';

export enum ServiceType {
  frontLoad = 'FrontLoad',
  cart = 'Cart',
  openTop = 'OpenTop',
  openTopTemp = 'OpenTopTemp',
  receiverBox = 'ReceiverBoxCompactor',
  selfContained = 'SelfContCompactor',
}

export enum FormServiceTypes {
  frontLoad = 'FrontLoad',
  cart = 'Cart',
  openTop = 'OpenTop',
  receiverBox = 'ReceiverBoxCompactor',
  selfContained = 'SelfContCompactor',
}

export enum OpenTopServiceType {
  temporary = 'Temporary',
  permanent = 'Permanent'
}

export enum FrequencyType {
  onCall = 'OnCall',
  scheduled = 'Scheduled'
}

export const SERVICE_TYPES: Record<ServiceType, string> = {
  [ServiceType.frontLoad]: translate('service.frontLoad'),
  [ServiceType.cart]: translate('service.cart'),
  [ServiceType.openTop]: translate('service.openTop'),
  [ServiceType.openTopTemp]: translate('service.openTopTemp'),
  [ServiceType.receiverBox]: translate('service.receiverBox'),
  [ServiceType.selfContained]: translate('service.selfContained'),
};

export const SERVICE_TYPES_WITH_OPEN_TOP_TYPES: Record<ServiceType, string> = {
  [ServiceType.frontLoad]: translate('service.frontLoad'),
  [ServiceType.cart]: translate('service.cart'),
  [ServiceType.openTop]: translate('service.openTopPermanent'),
  [ServiceType.openTopTemp]: translate('service.openTopTemporary'),
  [ServiceType.receiverBox]: translate('service.receiverBox'),
  [ServiceType.selfContained]: translate('service.selfContained'),
};

export const OPEN_TOP_SERVICE_TYPES: Record<OpenTopServiceType, string> = {
  [OpenTopServiceType.temporary]: translate('service.temporary'),
  [OpenTopServiceType.permanent]: translate('service.permanent'),
};

export const getFormServiceType = (service: string): FormServiceTypes => {
  switch (service) {
    case ServiceType.openTop:
    case ServiceType.openTopTemp:
      return FormServiceTypes.openTop;
    default:
      return service as FormServiceTypes;
  }
};

export const getServiceTypeFromForm = 
  (serviceType: FormServiceTypes, openTopType?: OpenTopServiceType): ServiceType => {
    switch (serviceType) {
      case FormServiceTypes.openTop:
        return openTopType === OpenTopServiceType.temporary ? ServiceType.openTopTemp : ServiceType.openTop;
      default:
        return serviceType as string as ServiceType;
    }
  };
