import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { mapper } from 'utils/styles';

export const CheckboxCheck = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: middle;
  border: 1px solid ${transparentize(0.8, '#000')};
  border-radius: 3px;
  cursor: pointer;
`;

export const CheckboxText = styled.span`
  vertical-align: middle;
  font-size: 15px;
`;

export const CheckboxInput = styled.input`
  display: none;
  &:checked + ${CheckboxCheck} {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDBIMkMuOSAwIDAgLjkgMCAydjE0YzAgMS4xLjkgMiAyIDJoMTRjMS4xIDAgMi0uOSAyLTJWMmMwLTEuMS0uOS0yLTItMnpNNyAxNEwyIDkuMTkybDEuNC0xLjM0Nkw3IDExLjMwOCAxNC42IDQgMTYgNS4zNDYgNyAxNHoiIGZpbGw9IiMwMDk2ODgiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
    background-size: 100%;
    border: 0 solid ${props => props.theme.colors.brandPrimary};
  }
`;
export interface CheckboxProps{
  margin?: string
  isDisabled?: boolean
  noLabel: boolean
}
export const Checkbox = styled.label<CheckboxProps>`
  display: inline-block;
  margin: ${props => mapper({ no: 0, small: '15px', medium: '30px', large: '45px' }, props.margin, 'no')};
  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
    `};
  ${props =>
    props.noLabel &&
    css`
      ${CheckboxCheck} {
        margin-right: 0;
      }
    `};
`;

export interface CheckboxGroup{
  margin: string;
}

export const CheckboxGroup = styled.div<CheckboxGroup>`
  margin: ${props => mapper({ no: 0, small: '15px', medium: '30px', large: '45px' }, props.margin, 'medium no')};
  ${Checkbox} {
    display: block;
    margin: 0 0 10px;
  }
`;
