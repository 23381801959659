import React, { PropsWithChildren, Suspense } from 'react';

import { Navigate, Path } from 'react-router';

import account from 'account/ducks/account';
import { UserRole } from 'contracts/enums';
import PageLoading from 'core/components/styled/PageLoading';

const RoleRoute: React.FC<PropsWithChildren<ComponentProps>> = ({ children, roles, hasAccessIf, redirectTo = '/' }) => {
  const hasRole = account.selectors.hasRole(roles);
  const hasCheckoutPage = account.selectors.hasIndependentCheckoutPage();
  let hasAccess = true;

  if (hasAccessIf === 'hasCheckout') {
    hasAccess = hasCheckoutPage;
  }
  const path: Path = { pathname: location.pathname, search: location.search, hash: location.hash };
  
  return hasAccess && hasRole ?
    <Suspense fallback={<PageLoading />}>{children}</Suspense> :
    <Navigate to={redirectTo} state={path}/>;
};

interface ComponentProps {
  roles: UserRole[],
  hasAccessIf?: string,
  redirectTo?: string
}

export default RoleRoute;
