import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { BareButtonStyle } from 'core/components/styled/Button';
import { Sizes } from 'core/styles';
import { slideInUp } from 'core/styles/animations';
import { mapper, iterator } from 'utils/styles';

import Icon from '../Icon';

export const CardTitle = styled.span`
  width: 100%;
  margin-bottom: 10px;
  line-height: 29px;
  font-weight: bold;
  font-size: 16px;
`;

export const CardImageContainer = styled.div`
  flex-basis: 30%;
`;

export const CardExtraDetailLabel = styled.span`
  display: block;
  font-size: 13px;
`;

export const CardExtraDetailValue = styled.span`
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  color: ${props => props.theme.colors.brandPrimary};
`;

export const CardExtraDetail = styled.div`
  margin-right: 15px;
  text-align: right;

  &:last-child {
    margin-right: 0;
  }
`;

export const CardExtraDetails = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  display: flex;
`;

export const CardSecondaryDetailLabel = styled.span`
  display: block;
  text-transform: capitalize;
  line-height: 18px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 12px;
  color: ${transparentize(0.6, '#000')};
`;

export const CardSecondaryDetailValue = styled.span`
  display: block;
  line-height: 18px;
  font-size: 14px;
`;

interface CardSecondaryDetailProps {
  width?: string;
}
export const CardSecondaryDetail = styled.div<CardSecondaryDetailProps>`
  margin: 0 20px 12px 0;
  ${props =>
    props.width &&
    css`
      flex-basis: ${props.width};
      margin-right: 0;
    `};
`;

export const CardSecondaryDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 70%;
  padding: 15px 40px 15px 20px;
`;

export const CardPrimaryDetailLabel = styled.span`
  display: block;
  text-transform: capitalize;
  line-height: 30px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 16px;
`;

export const CardPrimaryDetailValue = styled.span`
  display: block;
  line-height: 38px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 32px;
  color: ${props => props.theme.colors.brandPrimary};
`;

export const CardPrimaryDetail = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardPrimaryDetails = styled.div`
  flex-basis: 30%;
  padding: 15px 20px 30px 0;
  text-align: right;
`;

export const CardDetails = styled.div`
  display: flex;
  position: relative;
  min-height: 165px;
`;

export const CardActionIcon = styled(Icon)`
  width: 22px;
  height: 22px;
  margin-bottom: 10px;
`;

export const CardAction = styled.button`
  ${BareButtonStyle};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-transform: uppercase;
  color: ${props => props.theme.colors.brandPrimary};
`;

export const CardActions = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${transparentize(0.05, '#fff')};
`;

export const CardToggle = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  display: block;
  width: 100%;
  padding: 12px 0;
  cursor: pointer;
  background-color: #fff;
  border-top: 1px solid ${transparentize(0.9, '#000')};
  text-transform: capitalize;
  text-align: center;
  line-height: 20px;
  font-size: 15px;
  color: ${props => props.theme.colors.brandPrimary};

  &:active {
    background-color: #fafcfc;
  }
`;

export const CardListItemLabel = styled.span`
  flex-basis: 40%;
`;

export const CardListItemValue = styled.span`
  flex-basis: 60%;
  text-align: right;
`;

export const CardListItem = styled.div`
  display: flex;
  padding: 8px 20px;
  font-weight: ${props => props.theme.fontWeightLight};

  &:nth-child(2n + 1) {
    background-color: #f5f7f7;
  }
`;

export const CardList = styled.div`
  display: none;
`;

interface CardTableOptionProps {
  isSelected: boolean;
}
export const CardTableOption = styled.button.attrs({ type: 'button' })<CardTableOptionProps>`
  ${BareButtonStyle};
  display: inline-block;
  padding: 8px 10px;
  text-align: left;
  border: 2px solid transparent;
  border-radius: 4px;

  &:hover {
    background-color: #fff;
    border-color: ${props => props.theme.colors.brandPrimary};
  }

  ${props =>
    props.isSelected &&
    css`
      &,
      &:hover {
        background-color: ${props => props.theme.colors.brandPrimary};
        color: #fff;
      }
    `};
`;

export const CardTableOptionLabel = styled.span`
  font-size: 11px;
`;

export const CardTableOptionValue = styled.span`
  display: block;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface CardTableCellProps {
  secondary?: boolean;
  select?: boolean;
}
export const CardTableCell = styled.td<CardTableCellProps>`
  padding: 12px 13px;
  font-size: 14px;

  ${props =>
    props.secondary &&
    css`
      font-weight: ${props => props.theme.fontWeightLight};
      color: ${transparentize(0.6, '#000')};
    `};

  ${props =>
    props.select &&
    css`
      padding: 4px 0;
    `};
`;

export const CardTableRow = styled.tr`
  background-color: #fff;

  &:nth-child(2n + 1) {
    background-color: #f5f7f7;
  }
`;

export const CardTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

interface CardProps {
  padding?: string;
  animated?: boolean;
  margin?: string;
  isSelected?: boolean;
  isExpanded?: boolean;
}
export const Card = styled.div<CardProps>`
  min-width: 480px; 
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 2px 4px ${transparentize(0.9, '#000')};
  padding: ${props => mapper({ no: '0px', small: '10px', medium: '20px', large: '40px' }, props.padding, Sizes.SIZE_NO)};

  &:hover {
    ${CardActions} {
      display: flex;
    }
  }

  ${props =>
    props.animated &&
    css`
      animation: ${slideInUp} 0.3s ease-out backwards;
      ${iterator(2, step => `&:nth-of-type(${step + 2}) {animation-delay: ${(step + 1) * 0.2}s;}`)};
    `};

  ${props =>
    props.margin &&
    css`
      margin-bottom: ${mapper({ no: 0, small: '20px', medium: '30px', large: '40px' }, props.margin)};
    `};

  ${props =>
    props.isSelected &&
    css`
      border: 2px solid ${props => props.theme.colors.brandPrimary};
    `};

  ${props =>
    props.isExpanded &&
    css`
      ${CardList} {
        display: block;
      }
    `};
`;
