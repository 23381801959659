import { transparentize } from 'polished';
import styled, { css, LoadingStyledComponentProps } from 'styled-components';

import { loadingOverlay, zoomIn } from 'core/styles';
import { mapper } from 'utils/styles';

import { BareButtonStyle } from './Button';
import quickSalePortalScrollbar from './Scrollbar';

export const ModalClose = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  position: absolute;
  top: 20px;
  right: 20px;
  width: 13px;
  height: 13px;
  background: center no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlLXdpZHRoPSIxLjQiIHN0cm9rZT0iIzAwMCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj48cGF0aCBkPSJNMS4zNzMgMS4xM2wxMC4yNTQgMTAuNzRNMTEuNjI3IDEuMTNMMS4zNzMgMTEuODciLz48L2c+PC9zdmc+);
`;

interface ModalTitleProps {
  bold?: boolean;
  center?: boolean;
  margin?: string;
  thinFont?: boolean;
  capitalize?: boolean;
}
export const ModalTitle = styled.h2<ModalTitleProps>`
  margin-bottom: 30px;
  line-height: 29px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 24px;

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}

  ${props =>
    props.margin &&
    css`
      margin: ${mapper({ no: '0', small: '20px', medium: '30px', large: '40px' }, props.margin)};
    `};

  ${props =>
    props.thinFont &&
    css`
      font-weight: ${props => props.theme.fontWeightLight};
    `};

  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};
`;

interface ModalDialogProps {
  centered?: boolean;
}
export const ModalDialog = styled.div<ModalDialogProps>`
  position: relative;
  width: 650px;
  max-height: 90vh;
  padding: 45px 55px 45px;
  overflow-y: auto;
  ${quickSalePortalScrollbar()}
  background: #fff;
  box-shadow: 0 2px 4px ${transparentize(0.9, '#000')};
  animation: ${zoomIn} 0.3s ease-out;
  border-radius: 7px;

  ${props =>
    props.centered &&
    css`
      text-align: center;
      padding: 45px 145px 80px;
    `};
`;

interface ModalProps extends LoadingStyledComponentProps {
  size?: string;

}
export const Modal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${transparentize(0.4, '#000')};

  ${props =>
    props.isLoading &&
    css`
      ${ModalDialog} {
        ${loadingOverlay(26)};
      }
    `};

  ${props =>
    props.size &&
    css`
      ${ModalDialog} {
        width: ${mapper({ small: '500px', medium: '600px', large: '700px' }, props.size)};
      }
    `};
`;

export const Confirmation = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  width: 100vw;
  height: 100vh;
  background-color: ${transparentize(0.4, '#000')};
`;

export const ConfirmationDialog = styled.div`
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px ${transparentize(0.9, '#000')};
  line-height: 20px;
  font-size: 15px;
  animation: ${zoomIn} 0.3s ease-out;
`;

export const ConfirmationTitle = styled.span`
  display: block;
  margin-bottom: 8px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 18px;
`;

export const ConfirmationMessage = styled.span`
  display: block;
  margin-bottom: 3px;
  line-height: 18px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 14px;
  color: ${transparentize(0.5, '#000')};
`;
