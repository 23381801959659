import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ModalType } from 'contracts/enums';
import { ModalStateProps } from 'contracts/types/modal';
import { ApplicationState } from 'contracts/types/state';
import modalManager from 'core/ducks/modalManager';
import { DisablePageScroll, EnablePageScroll } from 'core/utils/helpers/pageScroll';
import ShoppingCartDetailsModal from 'dashboard/components/modals/ShoppingCartDetailsModal';
import UploadBulkFileModal from 'market/components/modals/UploadBulkFileModal';
import PromoCodeModal from 'quote/components/modals/PromoCodeModal';
import UploadFileModal from 'quote/components/modals/UploadFileModal';
import ContactFormModal from 'sale/components/modals/ContactFormModal';
import UserEditorModal from 'users/components/modals/UserEditorModal';
import ServiceUnavailableReasonModal from 'vendor/components/modals/ServiceUnavailableReasonModal';

import ConfirmationModal from './ConfirmationModal';
import { useModalManager, useOnLocationChange } from 'core/utils/helpers/hooks';

const modalDictionary: Record<ModalType, React.ElementType> = {
  [ModalType.confirmation]: ConfirmationModal,
  [ModalType.uploadFile]: UploadFileModal,
  [ModalType.shoppingCartDetails]: ShoppingCartDetailsModal,
  [ModalType.uploadPromoCode]: PromoCodeModal,
  [ModalType.uploadBulkFile]: UploadBulkFileModal,
  [ModalType.contactForm]: ContactFormModal,
  [ModalType.usersForm]: UserEditorModal,
  [ModalType.serviceUnavailableReason]: ServiceUnavailableReasonModal,
};

// Modal manager usage:
// const { openModal } = useModalManager();
// openModal({
//   modalType: ModalType,
//   props: ModalProps extends ModalComponentProps
// });
export const ModalManager: React.FC = () => {
  const { closeModal } = useModalManager();
  
  useOnLocationChange(() => {
    modals.forEach(modal => {
      if (modal.isOpen) {
        closeModal(modal.id);
      }
    });
  });

  const modals = useSelector(
    (state: ApplicationState) => state.core.modalManager.modals
  );

  const renderModal = (modal: ModalStateProps): JSX.Element | null => {
    const Component = modalDictionary[modal.modalType];
    if (Component) {
      const alreadyOpened = modals.find(m => m.id === modal.id)?.isOpen;
      if (!alreadyOpened) {
        const onModalClose = (value?: boolean): void => {
          if (componenetModalClose) {
            componenetModalClose(value);
          }
          closeModal(modal.id);
          modal.isOpen = false;
          if (!modals.find(m => m.isOpen)) {
            EnablePageScroll();
          }
        };

        const componenetModalClose = modal.props.closeModal;
        modal.props.closeModal = onModalClose;
        modal.isOpen = true;
      }
      DisablePageScroll();
      return <Component key={modal.id} { ...modal.props} />;
    }
    return null;
  };

  return (
    <React.Fragment>
      {modals.map(modal => renderModal(modal))}
    </React.Fragment>
  );
};

