import { useMemo } from 'react';

import update from 'immutability-helper';
import intersection from 'lodash-es/intersection';
import { useSelector } from 'react-redux';
import { createSelector, OutputParametricSelector } from 'reselect';

import { UserRole } from 'contracts/enums';
import { AccountAction } from 'contracts/types/action';
import { SuccessLoginModel } from 'contracts/types/response';
import {
  ApplicationState,
  AccountState,
  ReduceFunctionMap
} from 'contracts/types/state';
import { GLOBAL_RESET } from 'core/constants/globalActionTypes';
import { destroySession, getSessionUser } from 'core/utils/helpers/session';
import { getReducerBuilder } from 'core/utils/reducer/reducerBuilder';

// Actions Keys
const ROOT_KEY = 'account/login';
enum ActionKey {
  LOGIN = 'account/login/LOGIN',
  FAIL_LOGIN = 'account/login/FAIL_LOGIN'
}

// Initial State
const getInitialState: () => AccountState = () => {
  return {
    user: getSessionUser() || undefined,
    errorMessage: undefined
  };
};

// Reducer
const reducerKeys = [
  ActionKey.LOGIN,
  ActionKey.FAIL_LOGIN,
] as const;
type ReducerKey = typeof reducerKeys[number];

const reducerFunctionMap: ReduceFunctionMap<
  ReducerKey,
  AccountState,
  AccountAction
> = {
  [ActionKey.LOGIN]: (state, action) => {
    const {
      user,
    } = action;
    return update(state, {
      $merge: {
        user,
        errorMessage: undefined
      }
    });
  },
  [ActionKey.FAIL_LOGIN]: (state, action) => {
    const {
      errorMessage,
    } = action;
    return update(state, {
      $merge: {
        user: undefined,
        errorMessage
      }
    });
  },
};

export const reducer = getReducerBuilder<
AccountState,
AccountAction
>(ROOT_KEY, getInitialState)
  .withReduceFunctionMap(reducerFunctionMap)
  .withReset(GLOBAL_RESET)
  .buildReducer();

// Actions
const actionMap = {
  LOGIN: (user: SuccessLoginModel): AccountAction => ({
    type: ActionKey.LOGIN,
    user
  }),
  LOGIN_FAILED: (errorMessage: string): AccountAction => {
    destroySession();
    return {
      type: ActionKey.FAIL_LOGIN,
      errorMessage
    };
  },
  RESET: (): AccountAction => {
    destroySession();
    return {
      type: GLOBAL_RESET,
    };
  }
};

// Selector helpers
function GetSelector<DataType>(
  selector: (state: ApplicationState) => DataType,
): DataType {
  const data = useSelector((state: ApplicationState) => selector(state));
  return data;
}

function GetParametricSelector<DataType, PropsType, Res1Type>(
  selector: () => OutputParametricSelector<
    ApplicationState,
    PropsType,
    DataType,
    (res1: Res1Type, res2: PropsType) => DataType
  >,
  props: PropsType,
): DataType {
  const memoizedSelector = useMemo(selector, []);
  const data = useSelector((state: ApplicationState) =>
    memoizedSelector(state, props),
  );
  return data;
}

// Selectors
const getCurrentUser = (state: ApplicationState): SuccessLoginModel => state.account.user;
const getCurrentUserRoles = (state: ApplicationState): UserRole[] => 
  state.account.user ? state.account.user.roles || [] : [];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getHasRole = () =>
  createSelector(
    getCurrentUserRoles,
    (_: ApplicationState, roleSelectorProps: { roles: UserRole[] }) =>
      roleSelectorProps,
    (currentUserRoles, roleSelectorProps) => {
      if (roleSelectorProps.roles && currentUserRoles.length > 0) {
        return !!intersection(currentUserRoles, roleSelectorProps.roles).length;
      }
      return false;
    },
  );

// Flags
const isCurrentUserLoggedIn = (state: ApplicationState): boolean => {
  const user = getCurrentUser(state);
  return !!user;
};
const getIsAdmin = (state: ApplicationState): boolean => {
  const roles = getCurrentUserRoles(state);
  return !!intersection(roles, [UserRole.admin]).length;
};
const getIsConfigurationUser = (state: ApplicationState): boolean => {
  const roles = getCurrentUserRoles(state);
  return !!intersection(roles, [UserRole.configurationUser]).length;
};
const getIsManager = (state: ApplicationState): boolean => {
  const roles = getCurrentUserRoles(state);
  return !!intersection(roles, [UserRole.manager]).length;
};
const getIsSalesPerson = (state: ApplicationState): boolean => {
  const roles = getCurrentUserRoles(state);
  return !!intersection(roles, [UserRole.salesPerson]).length;
};
const getIsSalesIHR = (state: ApplicationState): boolean => {
  const roles = getCurrentUserRoles(state);
  return !!intersection(roles, [UserRole.salesIHR]).length;
};
const getHasIndependentCheckoutPage = (state: ApplicationState): boolean => {
  const user = getCurrentUser(state);
  return user && !!intersection(user.functionalAccess, ['IndependentCheckoutPage']).length;
};

const account = {
  action: {
    login: actionMap.LOGIN,
    loginFailed: actionMap.LOGIN_FAILED,
    logout: actionMap.RESET
  },
  selectors: {
    isLoggedIn: () => GetSelector(isCurrentUserLoggedIn),
    hasRole: (roles: UserRole[]) => GetParametricSelector(getHasRole, { roles }),
    isAdmin: () => GetSelector(getIsAdmin),
    isConfigurationUser: () => GetSelector(getIsConfigurationUser),
    isManager: () => GetSelector(getIsManager),
    isSalesPerson: () => GetSelector(getIsSalesPerson),
    isSalesIHR: () => GetSelector(getIsSalesIHR),
    hasIndependentCheckoutPage: () => GetSelector(getHasIndependentCheckoutPage),
    getCurrentUser: () => GetSelector(getCurrentUser),
  }
};
export default account;
