import moment from 'moment-timezone';

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const decimal = (value: number | string): string => Number(value).toFixed(2);

export const currency = (value: number | bigint): string => currencyFormatter.format(value || 0);

export const date = (value: moment.MomentInput): string => moment(value).format('L');

export const EDTDate = (value: moment.MomentInput): string =>
  moment
    .utc(value)
    .tz('America/New_York')
    .format('L');

export const arrayToLinesOfText = (array: string[]): string => (array && array.length ? array.join('\n') : '');
