import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { ApiUrlKey } from 'contracts/enums';
import { PriceQuoteSearchDataView } from 'contracts/types/request';
import { PagedDataViewListDataView, PriceQuoteListDataView, ServiceError } from 'contracts/types/response';
import http, { useQueryMutation, useReactQuery } from 'core/services/http';

export const useGetQuotes = (
  request: PriceQuoteSearchDataView,
  customOnSuccess?: (response: PagedDataViewListDataView<PriceQuoteListDataView>) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
    UseQueryResult<PagedDataViewListDataView<PriceQuoteListDataView>, AxiosError> => 
  useReactQuery(ApiUrlKey.getQuotes, async() => {
    const response = await http.get<PagedDataViewListDataView<PriceQuoteListDataView>>(ApiUrlKey.getQuotes, {
      params: request });
    return response.data;
  }, customOnSuccess, customOnError);

export const useGetQuoteDonwloadLink = (
  customOnSuccess?: (response: string) => void): 
  UseMutationResult<string, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.getQuoteDownloadLink,
    async(fileId: number) => {
      const response = await http.get<{alias: string}>(ApiUrlKey.getQuoteDownloadLink + `/${fileId}`);
      return response.data?.alias;
    }, customOnSuccess); 

export const useUploadFile = (
  customOnSuccess: () => void,
  customOnError: (err: AxiosError<ServiceError>) => void,
  onUploadProgress?: (progressEvent: unknown) => void): 
      UseMutationResult<Promise<never>, AxiosError<ServiceError>, FormData> => 
  useQueryMutation(
    ApiUrlKey.uploadBulkFile,
    async(request: FormData) => http.post(ApiUrlKey.uploadBulkFile, request, { onUploadProgress }),
    customOnSuccess, customOnError, false, true); 
