import { AxiosError } from 'axios';
import { UseMutationResult, UseQueryResult } from 'react-query';

import { ApiUrlKey } from 'contracts/enums';
import { DownloadProposalRequest, ShoppingCartFilterDataView } from 'contracts/types/request';
import { DashboardCartDataView, DashboardElectronicPapersDataView, GetBlobResponse, ListShoppingCartCustomerProfileSimpleDataView, ServiceError, ShoppingCartSubscriptionsDataView } from 'contracts/types/response';
import http, { useQueryMutation, useReactQuery } from 'core/services/http';
import { downloadBlob } from 'core/utils/helpers/downloadBlob';

export const useGetShoppingCarts = (
  requestType: string,
  request: ShoppingCartFilterDataView,
  customOnSuccess?: (response: ListShoppingCartCustomerProfileSimpleDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
  UseQueryResult<ListShoppingCartCustomerProfileSimpleDataView, AxiosError> => 
  useReactQuery([ApiUrlKey.getShoppingCarts, requestType], async() => {
    const response = await http.get<ListShoppingCartCustomerProfileSimpleDataView>(ApiUrlKey.getShoppingCarts, {
      params: request });
    return response.data;
  }, customOnSuccess, customOnError);

export const useLoadProposalCart = (
  customOnSuccess?: (response: ShoppingCartSubscriptionsDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
  UseMutationResult<ShoppingCartSubscriptionsDataView, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.loadProposalCart,
    async(proposalId: number) => {
      const response = await http.get<ShoppingCartSubscriptionsDataView>(ApiUrlKey.loadProposalCart + `/${proposalId}`);
      return response.data;
    }, customOnSuccess, customOnError); 

export const useLoadShoppingCart = (
  customOnSuccess?: (response: DashboardCartDataView) => void,
  customOnError?: (err: AxiosError<ServiceError>) => void): 
  UseMutationResult<DashboardCartDataView, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.loadShoppingCart,
    async(shoppingCartId: number) => {
      const response = await http.get<DashboardCartDataView>(ApiUrlKey.loadShoppingCart + `/${shoppingCartId}`);
      return response.data;
    }, customOnSuccess, customOnError); 

export const useOpenForSaleShoppingCartAsSalesPerson = (customOnError?: (err: AxiosError<ServiceError>) => void): 
  UseMutationResult<{url: string}, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.openForSaleShoppingCartAsSalesPerson,
    async(shoppingCartId: number) => {
      const response = await http.post<{url: string}>(ApiUrlKey.openForSaleShoppingCartAsSalesPerson + `/${shoppingCartId}`);
      return response.data;
    },
    undefined,
    customOnError,
    false); 

export const useGetShoppingCartDocumentsById = (): 
  UseMutationResult<DashboardElectronicPapersDataView, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.getShoppingCartDocumentsById,
    async(shoppingCartId: number) => {
      const response = await http.get<DashboardElectronicPapersDataView>(ApiUrlKey.getShoppingCartDocumentsById + `/${shoppingCartId}`);
      return response.data;
    },
    undefined,
    undefined,
    false); 

export const useDownloadProposalCart = (): 
  UseMutationResult<GetBlobResponse, AxiosError<ServiceError>, DownloadProposalRequest> => 
  useQueryMutation(
    ApiUrlKey.downloadProposalCart,
    async(request: DownloadProposalRequest) => {
      const response = await http.get<GetBlobResponse>(ApiUrlKey.downloadProposalCart + `/${request.proposalId}`, {
        responseType: 'blob',
        transformResponse: data => ({
          blob: data,
          filename: request.fileName
        })
      });
      return response.data;
    }, response => {
      downloadBlob(response.blob, response.filename);
    });

export const useCreateProposalCart = (customOnSuccess: () => void): 
  UseMutationResult<unknown, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.createProposalCart,
    async(proposalId: number) => await http.post(ApiUrlKey.createProposalCart + `/${proposalId}`),
    customOnSuccess); 

export const useDeleteProposalCart = (customOnSuccess: () => void): 
  UseMutationResult<unknown, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.deleteProposalCart,
    async(proposalId: number) => await http.delete(ApiUrlKey.deleteProposalCart + `/${proposalId}`),
    customOnSuccess);

export const useDeleteShoppingCart = (customOnSuccess: () => void): 
  UseMutationResult<unknown, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.deleteShoppingCart,
    async(shoppingCartId: number) => await http.post(ApiUrlKey.deleteShoppingCart + `/${shoppingCartId}`),
    customOnSuccess);

export const useResendShoppingCart = (customOnSuccess: () => void): 
  UseMutationResult<unknown, AxiosError<ServiceError>, number> => 
  useQueryMutation(
    ApiUrlKey.resendShoppingCart,
    async(shoppingCartId: number) => await http.post(ApiUrlKey.resendShoppingCart + `/${shoppingCartId}`),
    customOnSuccess);
