import React from 'react';

import isNumber from 'lodash-es/isNumber';
import round from 'lodash-es/round';
import sum from 'lodash-es/sum';

import { OpenTopServiceType, ServiceType } from 'contracts/enums/ServiceType';
import { DashboardCartLocationDataView, DashboardCartServiceDataView, SaleMarketConfigDataView, ServiceRatesDataView, ShoppingCartLocationDataView, ShoppingCartServiceDataView } from 'contracts/types/response';
import {
  Card, CardAction,
  CardActionIcon, CardActions, CardDetails, CardExtraDetail,
  CardExtraDetailLabel, CardExtraDetails, CardExtraDetailValue, CardImageContainer, CardSecondaryDetail,
  CardSecondaryDetailLabel, CardSecondaryDetails, CardSecondaryDetailValue, CardTitle
} from 'core/components/styled/Card';
import { currency } from 'core/utils/helpers/formatter';
import translate from 'core/utils/helpers/translate';

import ServiceTypeImage from './styled/ServiceTypeImage';

const ServiceDetails: React.FC<ComponentProps> = ({
  location,
  service,
  allRules,
  isSelected,
  showActions,
  editService,
  removeService
}) => {
  const onEditService = (): void => {
    if (editService) editService(location.salesForceSiteId, service.salesForceOpportunityId);
  };

  const onRemoveService = (): void => {
    if (removeService) removeService(location.salesForceSiteId, service.salesForceOpportunityId);
  };
  
  const serviceType = service.serviceType as ServiceType;

  const openTopAndCompactorPriceLabels = (price: ServiceRatesDataView): JSX.Element => (
    <CardExtraDetails>
      {isNumber(price.haul) ?
        <CardExtraDetail>
          <CardExtraDetailValue>{currency(price.haul)}</CardExtraDetailValue>
          <CardExtraDetailLabel>{translate('service.perHaul')}</CardExtraDetailLabel>
        </CardExtraDetail>
        : null}
      {isNumber(price.disposal) ?
        <CardExtraDetail>
          <CardExtraDetailValue>{currency(price.disposal)}</CardExtraDetailValue>
          <CardExtraDetailLabel>{translate('service.perTon')}</CardExtraDetailLabel>
        </CardExtraDetail>
        : null}
    </CardExtraDetails>
  );

  const getExtraDetails = (): JSX.Element | null => {
    const { price } = service;
    switch (serviceType) {
      case ServiceType.frontLoad: {
        const fee = round(sum([price.pickup, price.lockbar, price.casters, price.monthlyLiquidatedDamages]), 2);
        return (
          <CardExtraDetails>
            <CardExtraDetail>
              <CardExtraDetailValue>{currency(fee)}</CardExtraDetailValue>
              <CardExtraDetailLabel>{translate('service.perMonth')}</CardExtraDetailLabel>
            </CardExtraDetail>
          </CardExtraDetails>
        );
      }
      case ServiceType.cart: {
        const fee = round(sum([price.pickup, price.monthlyLiquidatedDamages]), 2);
        return (
          <CardExtraDetails>
            <CardExtraDetail>
              <CardExtraDetailValue>{currency(fee)}</CardExtraDetailValue>
              <CardExtraDetailLabel>{translate('service.perMonth')}</CardExtraDetailLabel>
            </CardExtraDetail>
          </CardExtraDetails>
        );
      }
      case ServiceType.openTop:
        if (service.openTopType === OpenTopServiceType.temporary) {
          return (
            <CardExtraDetails>
              {isNumber(price.disposal) ?
                <CardExtraDetail>
                  <CardExtraDetailValue>{currency(price.disposal)}</CardExtraDetailValue>
                  {isNumber(price.tonnage) ?
                    <CardExtraDetailLabel>
                      {price.tonnage} {translate('service.tonsIncluded')}
                    </CardExtraDetailLabel>
                    : null}
                </CardExtraDetail>
                : null}
            </CardExtraDetails>
          );
        } else {
          return openTopAndCompactorPriceLabels(price);
        }
      case ServiceType.selfContained: 
      case ServiceType.receiverBox: {
        return openTopAndCompactorPriceLabels(price);
      }
    }
    return null;
  };

  const getExtras = (): string => {
    const { price } = service;
    if (price.lockbar && price.casters) return translate('service.lockbarCasters');
    if (price.casters) return translate('service.castersSecond');
    return price.lockbar ? translate('service.lockbarSecond') : '-';
  };

  const getDetails = (): JSX.Element[] => {
    const equipmentLabel = allRules ? 
      allRules.equipmentLabels.find(e => e.code === service.equipmentType) : undefined;
    const materialLabel = allRules ? 
      allRules.materialLabels.find(e => e.code === service.wasteType) : undefined;
    const frequencyLabel = allRules ? 
      allRules.frequencyLabels.find(e => e.code === service.recurringFrequency) : undefined;

    const openTopCardDetails: DetailsItem[] = [
      {
        label: translate('service.duration'),
        value: translate('service.permanent'),
        width: 30
      },
      {
        label: translate('service.size'),
        value: equipmentLabel ? equipmentLabel.label : service.equipmentType,
        width: 30
      },
      {
        label: translate('service.extras'),
        value: service.price.liner ? translate('service.linerSecond') : '-',
        width: 40
      },
      {
        label: translate('service.wasteType'),
        value: materialLabel ? materialLabel.label : service.wasteType,
        width: 60
      },
      {
        label: translate('service.frequency'),
        value: frequencyLabel ? frequencyLabel.label : service.recurringFrequency,
        width: 40
      }
    ];

    const details: DetailsItem[] = [];

    switch (serviceType) {
      case ServiceType.frontLoad:
        details.push({
          label: translate('service.containers'),
          value: service.numberOfContainers,
          width: 30
        },
        {
          label: translate('service.size'),
          value: equipmentLabel ? equipmentLabel.label : service.equipmentType,
          width: 30
        },
        {
          label: translate('service.extras'),
          value: getExtras(),
          width: 40
        },
        {
          label: translate('service.wasteType'),
          value: materialLabel ? materialLabel.label : service.wasteType,
          width: 60
        },
        {
          label: translate('service.frequency'),
          value: frequencyLabel ? frequencyLabel.label : service.recurringFrequency,
          width: 40
        });
        break;
      case ServiceType.cart:
        details.push({
          label: translate('service.containers'),
          value: service.numberOfContainers,
          width: 60
        },
        {
          label: translate('service.size'),
          value: equipmentLabel ? equipmentLabel.label : service.equipmentType,
          width: 40
        },
        {
          label: translate('service.wasteType'),
          value: materialLabel ? materialLabel.label : service.wasteType,
          width: 60
        },
        {
          label: translate('service.frequency'),
          value: frequencyLabel ? frequencyLabel.label : service.recurringFrequency,
          width: 40
        });
        break;
      case ServiceType.openTop:
        if (service.openTopType === OpenTopServiceType.temporary) {
          details.push({
            label: translate('service.duration'),
            value: translate('service.temporary'),
            width: 60
          },
          {
            label: translate('service.size'),
            value: equipmentLabel ? equipmentLabel.label : service.equipmentType,
            width: 40
          },
          {
            label: translate('service.wasteType'),
            value: materialLabel ? materialLabel.label : service.wasteType,
            width: 60
          },
          {
            label: translate('service.extras'),
            value: service.price.liner ? translate('service.linerSecond') : '-',
            width: 40
          });
        } else {
          details.push(...openTopCardDetails);
        }
        break;
      case ServiceType.selfContained:
      case ServiceType.receiverBox:
        details.push(...openTopCardDetails);
        break;
    }
    return details.map((item, index) => 
      <CardSecondaryDetail key={index} width={`${item.width}%`}>
        <CardSecondaryDetailLabel>{item.label}</CardSecondaryDetailLabel>
        <CardSecondaryDetailValue>{item.value}</CardSecondaryDetailValue>
      </CardSecondaryDetail>);
  };

  const serviceLabel = allRules ? 
    allRules.serviceLabels.find(e => e.code === service.serviceType) : undefined;

  return (
    <Card isSelected={isSelected}>
      <CardDetails>
        <CardImageContainer>
          <ServiceTypeImage type={serviceType} />
        </CardImageContainer>

        {getExtraDetails()}

        <CardSecondaryDetails>
          <CardTitle>{serviceLabel ? serviceLabel.label : serviceType}</CardTitle>

          {getDetails()}
        </CardSecondaryDetails>

        {showActions && (
          <CardActions>
            <CardAction onClick={onEditService}>
              <CardActionIcon icon="edit" /> {translate('edit')}
            </CardAction>

            <CardAction onClick={onRemoveService}>
              <CardActionIcon icon="delete" /> {translate('delete')}
            </CardAction>
          </CardActions>
        )}
      </CardDetails>
    </Card>
  );
};

interface ComponentProps {
  location: ShoppingCartLocationDataView | DashboardCartLocationDataView;
  service: ShoppingCartServiceDataView | DashboardCartServiceDataView;
  allRules: SaleMarketConfigDataView | undefined;
  isSelected?: boolean;
  showActions?: boolean;
  editService?: (salesForceSiteId: string, salesForceOpportunityId: string) => void;
  removeService?: (salesForceSiteId: string, salesForceOpportunityId: string) => void;
}

interface DetailsItem {
  label: string;
  value: string | number | undefined | null;
  width: number;
}

export default ServiceDetails;
