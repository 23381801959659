import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Sizes } from 'core/styles';
import Colors from 'core/styles/colors';
import { mapper } from 'utils/styles';
import colorMapper, { ColorProps } from 'utils/styles/colorMapper';

import Icon from '../Icon';

export const BareButtonStyle = `
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  user-select: none;
`;

export const ButtonIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin: -3px 10px 0 0;
  vertical-align: middle;
`;

export interface ButtonProps extends ColorProps {
  size?: string;
  margin?: string;
  noBackground?: boolean;
  noTextTransform?: boolean;
  noPadding?: boolean;
  fluid?: boolean;
  square?: boolean;
  left?: boolean;
  line?: boolean;
  text?: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${BareButtonStyle};
  padding: ${props => mapper({ small: '6px 14px', medium: '11px 30px' }, props.size)};
  margin: ${props => mapper({ no: '0', small: '10px', medium: '15px', large: '20px' }, props.margin, Sizes.SIZE_NO)};
  background-color: ${props => colorMapper(props, Colors.primary)};
  border-radius: 40px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 14px;
  color: #fff;

  &:active {
    background-color: ${props => darken(0.025, colorMapper(props, Colors.primary))};
  }

  &:disabled {
    background-color: ${props => transparentize(0.5, colorMapper(props, Colors.primary))};
  }

  ${props =>
    props.noBackground &&
    css`
      background-color: white !important;
      color: #009d90;
      border: 1px solid #009d90;
      &:disabled {
        border-color: ${transparentize(0.5, colorMapper(props, Colors.primary))};
        color: ${transparentize(0.5, colorMapper(props, Colors.primary))};
      }
    `};

  ${props =>
    props.noTextTransform &&
    css`
      text-transform: none;
    `};

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `};

  ${props =>
    props.fluid &&
    css`
      flex: 1;
    `};

  ${props =>
    props.square &&
    css`
      border-radius: 4px;
      border: 1px solid ${colorMapper(props, Colors.primary)};
    `};

  ${props =>
    props.left &&
    css`
      margin-right: auto;
    `};

  ${props =>
    props.line &&
    css`
      background: transparent;
      border: 1px solid ${colorMapper(props, Colors.primary)};
      color: ${colorMapper(props, Colors.primary)};

      &:active {
        background: transparent;
        border-color: ${darken(0.025, colorMapper(props, Colors.primary))};
      }

      &:disabled {
        background: transparent;
        border-color: ${transparentize(0.5, colorMapper(props, Colors.primary))};
      }
    `};

  ${props =>
    props.text &&
    css`
      background: transparent;
      border: none;
      color: ${colorMapper(props, Colors.primary)};

      &:active {
        background: transparent;
        color: ${darken(0.025, colorMapper(props, Colors.primary))};
      }

      &:disabled {
        background: transparent;
        color: ${transparentize(0.5, colorMapper(props, Colors.primary))};
      }
    `};
`;

export const ButtonLink = Button.withComponent('a');
