import styled, { css } from 'styled-components';

import { ServiceType } from 'contracts/enums/ServiceType';
import cartContainerImg from 'core/assets/img/cartContainer.png';
import cartContainerImgRetina from 'core/assets/img/cartContainer_2x.png';
import frontLoadContainerImg from 'core/assets/img/frontLoadContainer.png';
import frontLoadContainerImgRetina from 'core/assets/img/frontLoadContainer_2x.png';
import openTopContainerImg from 'core/assets/img/openTopContainer.png';
import openTopContainerImgRetina from 'core/assets/img/openTopContainer_2x.png';
import selfContainedCntainerImg from 'core/assets/img/receiver_box.svg';
import receiverBoxImg from 'core/assets/img/self_contained_compactor.svg';
import { retinaImage, mapper } from 'utils/styles';

interface ServiceTypeImageProps {
  type: ServiceType;
  margin?: string;
  aspectRatio?: string;
}
const ServiceTypeImage = styled.div<ServiceTypeImageProps>`
  width: 100%;
  height: 100%;
  margin: ${props => mapper({ no: 0, small: '10px', medium: '20px', large: '30px' }, props.margin, 'no')};
  background: #02978b center center no-repeat;

  ${props =>
    props.aspectRatio &&
    css`
      height: auto;
      padding-top: ${props.aspectRatio};
    `};

  ${props =>
    props.type === ServiceType.frontLoad &&
    css`
      ${retinaImage(frontLoadContainerImg, frontLoadContainerImgRetina, '120% auto')};
    `};

  ${props =>
    props.type === ServiceType.cart &&
    css`
      ${retinaImage(cartContainerImg, cartContainerImgRetina, 'auto 90%')};
    `};

  ${props =>
    (props.type === ServiceType.openTop ||
      props.type === ServiceType.openTopTemp) &&
    css`
      ${retinaImage(openTopContainerImg, openTopContainerImgRetina, '90% auto')};
    `};

  ${props =>
    props.type === ServiceType.receiverBox &&
    css`
      ${retinaImage(receiverBoxImg, receiverBoxImg, '100px auto')};
    `};

  ${props =>
    props.type === ServiceType.selfContained &&
    css`
      ${retinaImage(selfContainedCntainerImg, selfContainedCntainerImg, '100px auto')};
    `};
`;

export default ServiceTypeImage;
